<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">deck</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Kit</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="kit.grupo"
              :disabled="isUpdating"
              :filter="filtrarObjetos"
              :items="grupos"
              hint="Obrigatório"
              :rules="[validacao.grupo == false || validacao.grupo, (value) => !!value || 'Obrigatório.']"
              label="Selecione o Grupo do Kit"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.grupo = false"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              background-color="white"
              v-model="kit.subgrupo"
              label="Subgrupo do Kit"
              maxlength="20"
              :rules="[validacao.subgrupo == false || validacao.subgrupo, (value) => valida_nome(value) || 'Subgrupo inválido']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                kit.subgrupo = pLetra(kit.subgrupo);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              background-color="white"
              v-model="kit.nome"
              label="Nome do Kit"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || 'Nome inválido',
                (value) => value.length > 2 || 'Preencha o nome do kit'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                kit.nome = pLetra(kit.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-currency-field
              v-model="kit.vlrVenda"
              label="Valor de Venda"
              hint="Obrigatório"
              :allowNegative="false"
              :max="999"
              :decimalLength="2"
              :rules="[validacao.vlrVenda == false || validacao.vlrVenda, (value) => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.vlrVenda = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-currency-field
              v-model="kit.vlrCusto"
              label="Custo do Kit"
              hint="Obrigatório"
              :allowNegative="false"
              :max="999"
              :decimalLength="2"
              :rules="[validacao.vlrCusto == false || validacao.vlrCusto, (value) => !!value || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.vlrCusto = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              background-color="white"
              v-model="kit.descricao"
              label="Descrição do Kit"
              maxlength="255"
              :rules="[validacao.descricao == false || validacao.descricao]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.descricao = false"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch
              class="mt-6"
              v-model="kit.situacao"
              :rules="[validacao.situacao == false || validacao.situacao]"
              :label="`${kit.situacao == 1 ? 'Ativo' : 'Inativo'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/kits" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      kit: {
        id: "",
        grupo: null,
        subgrupo: "",
        nome: "",
        vlrVenda: 0,
        vlrCusto: 0,
        descricao: "",
        situacao: 1
      },
      validacao: {
        id: false,
        grupo: false,
        subgrupo: false,
        nome: false,
        vlrVenda: false,
        vlrCusto: false,
        descricao: false,
        situacao: false,
        mensagemErro: false
      },

      grupos: [
        { id: 1, nome: "Encantando" },
        { id: 2, nome: "Celebrando" },
        { id: 3, nome: "Brindando" }
      ],

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/kit/" + this.id, this.kit);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/kit", this.kit);
          this.id = retorno.data.id;
          this.kit.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/kit");
          this.$router.go("/administrativo/kit");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/kits");
          }, 1000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  async created() {
    this.POSSUI_DIREITO();
    this.id = this.$route.params.id ? this.$route.params.id : "";

    try {
      if (this.id) {
        //* Se é edição, carrega o item
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/kit/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/kit");
        } else {
          this.kit = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
