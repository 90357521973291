<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'">Nosso Cardápio</p>
      </v-flex>

      <v-flex xs12>
        <v-carousel hide-delimiters :height="$vuetify.breakpoint.xs ? '650' : $vuetify.breakpoint.sm ? '550' : '700'">
          <!-- <template bottom v-slot:prev="{ on, attrs }"><v-btn text x-small rounded v-bind="attrs" v-on="on">Anterior</v-btn></template>
          <template bottom v-slot:next="{ on, attrs }"><v-btn text x-small rounded v-bind="attrs" v-on="on">Próximo</v-btn></template> -->
          <v-carousel-item v-for="(item, i) in itens" :key="i">
            <v-flex xs12 offset-md3 md6>
              <p v-if="!item.includes('hospedagem/kits')" :class="$vuetify.breakpoint.xs ? 'p12' : $vuetify.breakpoint.sm ? 'p16' : 'p20'" v-html="item"></p>
              <v-img v-if="item.includes('hospedagem/kits')" class="img-responsive" :src="item" :width="$vuetify.breakpoint.xs ? '500' : $vuetify.breakpoint.sm ? '550' : '600'">
              </v-img>
            </v-flex>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";

export default {
  data() {
    return {
      kits: "",
      itens: []
    };
  },
  async beforeMount() {
    const responseEnc = await api.get(process.env.VUE_APP_URL_WEB_API + "public/kit/grupo/1");
    const responseCel = await api.get(process.env.VUE_APP_URL_WEB_API + "public/kit/grupo/2");
    const responseBri = await api.get(process.env.VUE_APP_URL_WEB_API + "public/kit/grupo/3");

    var itensEnc = "";
    // <ul>
    //   <li>Buquê com 04 rosas vermelhas: R$ 85,00</li>
    //   <li>Buquê com 06 rosas vermelhas: R$ 145,00</li>
    //   <li>Buquê com 12 rosas vermelhas: R$ 210,00</li>
    //   <li>Orquídea Mini: R$ 70,00</li>
    //   <li>Orquídea Média: R$ 115,00</li>
    //   <li>Orquídea Grande: R$ 165,00</li>
    //   <li>Pétalas de rosas na banheira: R$ 20,00 (comprando uma das opções acima)</li>
    //   <li>Pétalas de rosas na banheira: R$ 40,00 (somente as pétalas)</li>
    //   <li>Caixa com 05 trufas de chocolate artesanal: R$ 30,00</li>
    //   <li>Caixa com 04 docinhos (brigadeiro, beijinho): R$ 20,00</li>
    //   <li>Mini bolos - R$ 55,00 ( chocolate, frutas e prestígio)</li>
    // </ul>
    var titulo = "";
    if (responseEnc.data.length) {
      for (let iEnc = 0; iEnc < responseEnc.data.length; iEnc++) {
        const enc = responseEnc.data[iEnc];
        if (enc.subgrupo && titulo != enc.subgrupo) {
          if (titulo) {
            itensEnc += "</ul><br />";
          }
          itensEnc += `<u>${enc.subgrupo}</u>:<br />`;
          itensEnc += "<ul>";
        }
        if (!itensEnc) {
          itensEnc += "<ul>";
        }
        itensEnc += `<li>${enc.nome}: ${"<span style='color: #009;'>" + util.formatCurrency(enc.vlrVenda, true) + "</span>"}${enc.descricao ? " - " + enc.descricao : ""}</li>`;
        titulo = enc.subgrupo;
      }
      itensEnc += "</ul>";
    }

    var itensCel = "";
    // <li>Tábua de queijos: R$ 117,00 - 04 tipos de queijos, fruta da estação, mini torradas e manteiga com ervas.</li>
    // <li>Tábua de mini pães e frios: 135,00 - 04 tipos de queijo, fruta da estação, salame italiano, presunto, azeitonas, pães, manteiga com ervas, geleias.</li>
    // <li>Mini fondue de chocolate: R$ 85,00 - ganache de chocolate ao leite servido em cerâmica com 02 opções de frutas da estação.</li>
    // <li>Fondue de queijo: R$ 96,00 - fondue de queijos nobres servido em pão artesanal com acompanhamentos: mini batatas, legumes da estação e pães.</li>
    // <li>Creme de Ervilhas: R$ 35,00 (porção individual). Servido em pão artesanal, acompanha queijo parmesão ralado.</li>
    titulo = "";
    if (responseCel.data.length) {
      for (let iEnc = 0; iEnc < responseCel.data.length; iEnc++) {
        const enc = responseCel.data[iEnc];
        if (enc.subgrupo && titulo != enc.subgrupo) {
          if (titulo) {
            itensCel += "</ul><br />";
          }
          itensCel += `<u>${enc.subgrupo}</u>:<br />`;
          itensCel += "<ul>";
        }
        if (!itensCel) {
          itensCel += "<ul>";
        }
        itensCel += `<li>${enc.nome}: ${"<span style='color: #009;'>" + util.formatCurrency(enc.vlrVenda, true) + "</span>"}${enc.descricao ? " - " + enc.descricao : ""}</li>`;
        titulo = enc.subgrupo;
      }
      itensCel += "</ul>";
    }

    var itensBri = "";
    // <u>Espumantes</u>:<br /><br />
    // <ul>
    //   <li>Espumante Salton Moscatel - R$ 51,00</li>
    //   <li>Espumante Salton Demi-Sec - R$ 51,00</li>
    //   <li>Espumante Freixenet  - Premum-Cava - Carta Nevada - R$ 91,00</li>
    //   <li>Espumante Freixenet Cordon Negro - Gran Selección - Brut - R$ 98,00</li>
    // </ul>
    // <br /><br />
    // <u>Vinhos</u>:<br /><br />
    // <ul>
    //   <li>Terranoble Chardonay Reserva - R$ 115,00</li>
    //   <li>Las Moras Sauvignon Blanc - R$ 60,00 (x2)</li>
    //   <li>Valduga Origem Cabernet Sauvignon - R$ 65,00 (x2)</li>
    //   <li>Falva Tamara Branco - R$ 68,00 (x2)</li>
    //   <li>Las Moras Malbec Barrel  Select - R$ 91,00</li>
    //   <li>Ramon Roqueta Tempranillo - R$ 97,00 ( x2)</li>
    //   <li>Paul Mas Claude Val Rouge - R$ 99,00</li>
    //   <li>Alves de Sousa Cume Tinto - R$ 105,00</li>
    //   <li>Saurus Pinot Noir - 120,00</li>
    // </ul>
    titulo = "";
    if (responseBri.data.length) {
      for (let iEnc = 0; iEnc < responseBri.data.length; iEnc++) {
        const enc = responseBri.data[iEnc];
        if (enc.subgrupo && titulo != enc.subgrupo) {
          if (titulo) {
            itensBri += "</ul><br />";
          }
          itensBri += `<u>${enc.subgrupo}</u>:<br />`;
          itensBri += "<ul>";
        }
        if (!itensBri) {
          itensBri += "<ul>";
        }
        itensBri += `<li>${enc.nome}: ${"<span style='color: #009;'>" + util.formatCurrency(enc.vlrVenda, true) + "</span>"}${enc.descricao ? " - " + enc.descricao : ""}</li>`;
        titulo = enc.subgrupo;
      }
      itensBri += "</ul>";
    }

    this.itens = [
      `Atendendo a pedidos e pensando em deixar a sua experiência ainda mais completa e inesquecível, apresentamos um cardápio com algumas sugestões para surpresas na chegada da hospedagem (Encantando), para deliciar-se sem preocupações (Celebrando) e para acompanhar as delícias e brindar os momentos especiais (Brindando).<br /><br />
        Os valores estão em cada item e o pagamento deverá ser efetuado no momento do pedido pelo whatsapp de atendimento por PIX ou Cartão de Crédito a Vista.`,
      `<b>Celebrando</b>: Algumas opções de alimentação para celebrar um momento especial, concentrando suas energias somente no passeio e na melhor companhia.<br /><br />
        <b>Brindando</b>: e para acompanhar a celebração, algumas opções de vinhos e espumantes.<br /><br />
        <b>Encantando</b>: para quem quiser surpreender o parceiro com um mimo especial, sempre preparado com todo o carinho e  personalização característico e nossas hospedagens.`,

      "hospedagem/kits/celebrando.jpg",
      `<b>CELEBRANDO</b><br /><br />
        <i>Importante: Pedidos deverão ser agendados até as 18hs - Prazo para entrega: 4 horas.</i><br /><br />
        ${itensCel}`,

      "hospedagem/kits/brindando.jpg",
      `<b>BRINDANDO</b><br /><br />
        <i>Importante: Pedidos deverão ser agendados até as 21hs - Prazo para entrega: 15 minutos.</i><br /><br />
        ${itensBri}`,

      "hospedagem/kits/encantando.jpg",
      `<b>ENCANTANDO</b><br /><br />
        <i>Importante: Pedidos deverão ser efetuados com pelo menos 48h de antecedência.</i><br /><br />
        ${itensEnc}`,
      "hospedagem/kits/encantando-rosas.jpg",
      "hospedagem/kits/encantando-orquideas.jpg"
    ];
  }
};
</script>

<style>
.ajustarImagem img {
  max-width: 100%;
}
.mosso-block-home:hover {
  box-shadow: 0 0.5rem 2rem #e1e1e1;
}
.mosso-block-home:hover {
  box-shadow: 0 0.5rem 2.5rem #e6e6e6;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  opacity: 1;
}
.mosso-block-home {
  display: block;
  color: #575757;
  font-weight: 400;
  transition: opacity 0.15s ease-out, box-shadow 0.15s ease-out, -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, box-shadow 0.15s ease-out;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, box-shadow 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
</style>
