<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 align-center style="padding: 0px">
        <span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          <p>Bem-vindos ao Espaço Mossoo!</p>
          <p>
            Agradecemos pela escolha do Espaço Mossoo para a sua hospedagem. A proposta do espaço é oferecer hospedagem em contato com a natureza, com um bom sono de reposição das
            energias, um bom café da manhã preparado valorizando a produção local e orgânica. Estaremos à disposição para que vocês tenham uma experiência maravilhosa conosco.
          </p>
          <p v-if="!somenteCardapio">
            Pensando na comodidade, segue abaixo algumas informações
            <b>importantes</b>:
          </p>
        </span>
      </v-flex>

      <v-expansion-panels v-model="painel" popout focusable v-if="!somenteCardapio">
        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Informações Gerais</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <BemVindos :cardapio.sync="cardapio"></BemVindos>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Cardápio</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <Cardapio id="kits"></Cardapio>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Banheira</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <BanheiraGas></BanheiraGas>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Chuveiro</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <ChuveiroGas></ChuveiroGas>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Televisor</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <Televisor></Televisor>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><span :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Ar Condicionado</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <ArCondicionado></ArCondicionado>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-flex xs12 align-center style="padding: 0px">
        <Cardapio v-if="somenteCardapio"></Cardapio>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '120px' : '80px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Cardapio from "./components/Cardapio.vue";
import Televisor from "./components/Televisor.vue";
import ChuveiroGas from "./components/ChuveiroGas.vue";
import BanheiraGas from "./components/BanheiraGas.vue";
import BemVindos from "./components/BemVindos.vue";
import ArCondicionado from "./components/ArCondicionado.vue";

export default {
  components: {
    Cardapio,
    Televisor,
    ChuveiroGas,
    BanheiraGas,
    BemVindos,
    ArCondicionado
  },
  data() {
    return { painel: 0, cardapio: false, somenteCardapio: false };
  },
  watch: {
    cardapio(val) {
      if (val) {
        setTimeout(() => {
          this.painel = 1;
        }, 500);
        setTimeout(() => {
          window.location.href = "#kits";
        }, 1000);
      }
    }
  },
  beforeMount() {
    if (this.$route.query.cardapio) {
      setTimeout(() => {
        this.painel = 1;
      }, 1000);
      setTimeout(() => {
        window.location.href = "#kits";
      }, 2000);
    }
    if (this.$route.query.somenteCardapio) {
      this.somenteCardapio = true;
    }
  }
};
</script>
