export default {
  UPDATE_LOGIN(state, payload) {
    state.login = payload;
  },
  UPDATE_MENU(state, payload) {
    //ADMIN
    state.modulo = "Administrativo"; //seta o nome do modulo
    state.itens = this.state.itensAministrativo;
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = Object.assign(state.usuario, payload);
  },
  MOSTRAR_OCULTAR_MENU(state) {
    state.drawer = !this.state.drawer;
  },
  MOSTRAR_MENU(state) {
    state.drawer = true;
  },
  OCULTAR_MENU(state) {
    state.drawer = false;
  },
  POSSUI_DIREITO(state, direito) {
    if (!state.usuario.id) {
      window.location = "/acesso-negado";
    }
  },
  MENSAGEM_ERRO(state, erro) {
    state.mensagemErro.tipo = erro.tipo;
    state.mensagemErro.mensagem = erro.mensagem;
  }
};
