<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">grid_on</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Tabela</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              autofocus
              v-model="tabela.idCabana"
              :disabled="isUpdating"
              :filter="filtrarObjetos"
              :items="cabanas"
              hint="Obrigatório"
              :rules="[validacao.idCabana == false || validacao.idCabana, (value) => !!value || 'Obrigatório.']"
              label="Selecione a Cabana"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idCabana = false"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md3>
            <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[validacao.dtInicio == false || validacao.dtInicio, (value) => valida_data(value) || 'Data inválida', (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtInicioUS = valida_data(dtInicio) ? formatDateUS(dtInicio) : null"
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 md3> </v-flex>

          <v-flex xs6>
            <span class="overline font-italic">Mínimo de Diárias</span>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-flex xs12 md2>
                <v-currency-field
                  v-model="tabela.minimoDiasUtil"
                  label="Dias Úteis"
                  :allowNegative="false"
                  :max="9999"
                  :decimalLength="0"
                  :rules="[validacao.minimoDiasUtil == false || validacao.minimoDiasUtil, (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.minimoDiasUtil = false"
                ></v-currency-field>
              </v-flex>

              <v-flex xs12 md2>
                <v-currency-field
                  v-model="tabela.minimoDiasFimDS"
                  label="Fins de Semana"
                  :allowNegative="false"
                  :max="9999"
                  :decimalLength="0"
                  :rules="[validacao.minimoDiasFimDS == false || validacao.minimoDiasFimDS, (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.minimoDiasFimDS = false"
                ></v-currency-field>
              </v-flex>

              <v-flex xs12 md2>
                <v-currency-field
                  v-model="tabela.minimoDiasFer"
                  label="Feriados"
                  :allowNegative="false"
                  :max="9999"
                  :decimalLength="0"
                  :rules="[validacao.minimoDiasFer == false || validacao.minimoDiasFer, (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.minimoDiasFer = false"
                ></v-currency-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs6>
            <span class="overline font-italic">Custo</span>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-flex xs12 md2>
                <v-currency-field
                  v-model="tabela.cartaoMossoo"
                  label="Asaas"
                  persistent-hint
                  :allowNegative="false"
                  :max="999"
                  :decimalLength="2"
                  :rules="[validacao.cartaoMossoo == false || validacao.cartaoMossoo, (value) => !!value || 'Obrigatório.']"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.cartaoMossoo = false"
                  suffix="%"
                ></v-currency-field>
              </v-flex>
              <v-flex xs12 md2>
                <v-currency-field
                  v-model="tabela.vlrCustoFixo"
                  label="Valor Custo Fixo"
                  :allowNegative="false"
                  :max="999"
                  :decimalLength="2"
                  :rules="[
                    validacao.vlrCustoFixo == false || validacao.vlrCustoFixo,
                    (value) => (value && parseFloat(value.replace(',', '.')) > 0 && value.length < 8) || 'Valor inválido'
                  ]"
                  :disabled="isUpdating"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @change="validacao.vlrCustoFixo = false"
                ></v-currency-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/tabelas" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
      <v-btn v-if="id != ''" :loading="isUpdating" color="green white" style="color: white" id="btn-rsn" outlined @click="salvar('copiar')">
        <v-icon id="icon-rsn" left>content_copy</v-icon>Salvar e Copiar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      tabela: {
        id: "",
        idCabana: null,
        dtInicio: null,
        minimoDiasUtil: 1,
        minimoDiasFimDS: 1,
        minimoDiasFer: 1,
        cartaoMossoo: 0,
        vlrCustoFixo: 0
      },
      validacao: {
        id: false,
        idCabana: false,
        dtInicio: false,
        minimoDiasUtil: false,
        minimoDiasFimDS: false,
        minimoDiasFer: false,
        cartaoMossoo: false,
        vlrCustoFixo: false,
        mensagemErro: false
      },

      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      cabanas: [],

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var erro = false;
      if (this.dtInicio != null) {
        if (util.valida_data(util.formatDateUS(this.dtInicio))) {
          this.tabela.dtInicio = util.formatDateUS(this.dtInicio);
        } else {
          erro = true;
          this.validacao.dtInicio = "Data inválida";
        }
      }
      if (erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/tabela/" + this.id + (novo == "copiar" ? "?copiar=1" : ""), this.tabela);
          this.id = retorno.data.id;
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/tabela", this.tabela);
          this.id = retorno.data.id;
          this.tabela.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/tabela");
          this.$router.go("/administrativo/tabela");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/tabelas");
          }, 1000);
        } else if (novo == "copiar") {
          this.$router.push("/administrativo/tabela");
          this.id = "";
          this.tabela.id = "";
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      return util.valida_data_BR(date);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    }
  },
  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    }
  },
  async created() {
    this.POSSUI_DIREITO();
    this.id = this.$route.params.id ? this.$route.params.id : "";

    const responseCabanas = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cabana/lista");
    this.cabanas = responseCabanas.data;

    try {
      if (this.id) {
        //* Se é edição, carrega o item
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/tabela/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/tabela");
        } else {
          this.tabela = response.data;
          this.dtInicioUS = util.dateToStringUS(this.tabela.dtInicio);
        }
      } else {
        if (this.cabanas.length == 1) {
          this.tabela.idCabana = this.cabanas[0].id;
        }
        this.dtInicio = util.formatDate(new Date());
        this.dtInicioUS = util.dateToStringUS(new Date());
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
