import Vue from "vue";
import Router from "vue-router";
import VueCookies from "vue-cookies";
import store from "./store"; //* Mecanismo de rotas

import Login from "./views/Login.vue";
import Vazio from "./views/Vazio.vue";
import AcessoNegado from "./AcessoNegado.vue";

import DeskTopADM from "./views/adm/Desktop.vue";

import Paises from "./views/adm/Paises.vue";
import Pais from "./views/adm/Pais.vue";

import UFs from "./views/adm/UFs.vue";
import UF from "./views/adm/UF.vue";

import Municipios from "./views/adm/Municipios.vue";
import Municipio from "./views/adm/Municipio.vue";

import Feriados from "./views/adm/Feriados.vue";
import Feriado from "./views/adm/Feriado.vue";

import Cabanas from "./views/adm/Cabanas.vue";
import Cabana from "./views/adm/Cabana.vue";

import Origens from "./views/adm/Origens.vue";
import Origem from "./views/adm/Origem.vue";

import Tabelas from "./views/adm/Tabelas.vue";
import Tabela from "./views/adm/Tabela.vue";

import Kits from "./views/adm/Kits.vue";
import Kit from "./views/adm/Kit.vue";

import Clientes from "./views/adm/Clientes.vue";
import Cliente from "./views/adm/Cliente.vue";

import Reservas from "./views/adm/Reservas.vue";

import Mapa from "./views/adm/Mapa.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Vazio,
      meta: {
        publico: true,
        somenteDeslogado: true
      }
    },
    {
      path: "/login",
      component: Login,
      meta: {
        publico: true,
        somenteDeslogado: true
      }
    },
    {
      path: "/acesso-negado",
      component: AcessoNegado
    },

    {
      path: "/administrativo",
      component: DeskTopADM
    },

    {
      path: "/administrativo/paises",
      component: Paises
    },
    {
      path: "/administrativo/pais",
      component: Pais
    },
    {
      path: "/administrativo/pais/:id",
      component: Pais
    },

    {
      path: "/administrativo/ufs",
      component: UFs
    },
    {
      path: "/administrativo/uf",
      component: UF
    },
    {
      path: "/administrativo/uf/:id",
      component: UF
    },

    {
      path: "/administrativo/municipios",
      component: Municipios
    },
    {
      path: "/administrativo/municipio",
      component: Municipio
    },
    {
      path: "/administrativo/municipio/:id",
      component: Municipio
    },

    {
      path: "/administrativo/feriados",
      component: Feriados
    },
    {
      path: "/administrativo/feriado",
      component: Feriado
    },
    {
      path: "/administrativo/feriado/:id",
      component: Feriado
    },

    {
      path: "/administrativo/cabanas",
      component: Cabanas
    },
    {
      path: "/administrativo/cabana",
      component: Cabana
    },
    {
      path: "/administrativo/cabana/:id",
      component: Cabana
    },

    {
      path: "/administrativo/origens",
      component: Origens
    },
    {
      path: "/administrativo/origem",
      component: Origem
    },
    {
      path: "/administrativo/origem/:id",
      component: Origem
    },

    {
      path: "/administrativo/tabelas",
      component: Tabelas
    },
    {
      path: "/administrativo/tabela",
      component: Tabela
    },
    {
      path: "/administrativo/tabela/:id",
      component: Tabela
    },

    {
      path: "/administrativo/kits",
      component: Kits
    },
    {
      path: "/administrativo/kit",
      component: Kit
    },
    {
      path: "/administrativo/kit/:id",
      component: Kit
    },

    {
      path: "/administrativo/clientes",
      component: Clientes
    },
    {
      path: "/administrativo/cliente",
      component: Cliente
    },
    {
      path: "/administrativo/cliente/:id",
      component: Cliente
    },

    {
      path: "/administrativo/reservas",
      component: Reservas
    },
    {
      path: "/administrativo/mapa",
      component: Mapa
    }
  ]
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const publico = to.matched.some((record) => record.meta.publico);
  const logout = to.matched.some((record) => record.meta.somenteDeslogado);
  const logado = VueCookies.get("token") ? true : false;

  if (!publico && !logado) {
    return next({
      path: "/login",
      query: to.fullPath != "/" ? { redirect: to.fullPath } : ""
    });
  }

  if (logado && logout) {
    return next("/");
  }

  store.commit("UPDATE_MENU", to);

  next();
});

export default router;
