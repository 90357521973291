<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">landscape</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Estado</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md4>
            <v-autocomplete
              autofocus
              v-model="uf.idPais"
              :disabled="isUpdating"
              :filter="filtrarObjetos"
              :items="paises"
              hint="Obrigatório"
              :rules="[validacao.idPais == false || validacao.idPais, (value) => !!value || 'Obrigatório.']"
              label="Selecione o país"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idPais = false"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs4 md2>
            <v-text-field
              background-color="white"
              v-model="uf.sigla"
              label="Sigla do Estado"
              maxlength="2"
              hint="Obrigatório"
              :rules="[validacao.sigla == false || validacao.sigla, (value) => !!value || 'Obrigatório.', (value) => (value && value.length == 2) || 'Preencha a sigla do estado']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                uf.sigla = uf.sigla.toUpperCase();
                validacao.sigla = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs8 md6>
            <v-text-field
              background-color="white"
              v-model="uf.nome"
              label="Nome do Estado"
              maxlength="32"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                (value) => !!value || 'Obrigatório.',
                (value) => valida_nome(value) || 'Nome inválido',
                (value) => value.length > 2 || 'Preencha o nome do estado'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                uf.nome = pLetra(uf.nome);
                validacao.nome = false;
              "
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/ufs" id="btn-rsn" color="orange lighten-2 white" style="color: white"> <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      uf: {
        id: "",
        idPais: null,
        sigla: null,
        nome: ""
      },
      validacao: {
        id: false,
        idPais: false,
        sigla: false,
        nome: false,
        mensagemErro: false
      },

      paises: [],
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/uf/" + this.id, this.uf);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/uf", this.uf);
          this.id = retorno.data.id;
          this.uf.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/uf");
          this.$router.go("/administrativo/uf");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/ufs");
          }, 1000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  async created() {
    this.POSSUI_DIREITO();
    this.id = this.$route.params.id ? this.$route.params.id : "";

    try {
      const responsePaises = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/pais/lista");
      this.paises = responsePaises.data;

      if (this.id) {
        //* Se é edição, carrega o item
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/uf/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/ufs");
        } else {
          this.uf = response.data;
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
