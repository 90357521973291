<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Hospedagem</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.hospedagem"></p>
      </v-flex>

      <Gracillis></Gracillis>

      <!-- <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h4' : 'h3'">Cabana Gracillis</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.gracillis"></p>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/externa_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
      </v-flex>

      <v-flex xs6 style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/banheiro1_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 70 : 230"> </v-img>
      </v-flex>
      <v-flex xs6 style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/banheiro2_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 70 : 230"> </v-img>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/quarto_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 100 : 380"> </v-img>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/bambu_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 40 : 100"> </v-img>
      </v-flex> -->

      <v-flex xs12 class="text-center" style="margin-top: 20px; padding: 0px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h4' : 'h3'">Reservas</p>

        <!-- <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Quer conhecer? <a class="estilo-link-site" @click="funcReservas">Faça aqui sua reserva</a> ou também estamos:</p> -->
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Quer conhecer? <a class="estilo-link-site" @click="funcContato">Entre em contato conosco</a> ou também estamos:
        </p>
        <br />
      </v-flex>

      <v-flex md4 xs3 style="padding: 0px; margin-top: -30px"></v-flex>
      <v-flex md2 xs3 style="margin-top: -30px" class="text-left">
        <a href="http://airbnb.com/h/gracillis-espacomossoo" target="_blank">
          <v-img class="img-responsive" src="hospedagem/airbnb.png" :width="$vuetify.breakpoint.xs ? 70 : 120"> </v-img>
        </a>
      </v-flex>
      <v-flex md2 xs3 style="margin-top: -10px; margin-left: 0px" class="text-right">
        <script
          type="application/javascript"
          async
          src="https://badge.hotelstatic.com/embed.js"
          data-url="https://www.booking.com/hotel/br/cabana-gracillis.html"
          :data-size="$vuetify.breakpoint.xs ? 40 : 60"
          data-position="inline"
          data-clickable="true"
        ></script>

        <!-- <a
          href="https://www.booking.com/hotel/br/cabana-gracillis.pt-br.html"
          target="_blank"
        >
          <v-img
            class="img-responsive"
            src="hospedagem/booking.png"
            :width="$vuetify.breakpoint.xs ? 70 : 120"
          >
          </v-img>
        </a> -->
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '130px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Gracillis from "./components/Gracillis.vue";

export default {
  components: {
    Gracillis
  },
  props: {
    reservas: { default: false },
    contato: { default: false }
  },
  data() {
    return {};
  },
  methods: {
    funcReservas() {
      this.$emit("update:reservas", true);
    },
    funcContato() {
      this.$emit("update:contato", true);
    }
  }
};
</script>
