<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#752921">
      <v-icon color="white" class="mr-2">share</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Módulo Administrativo</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout wrap>
        <v-flex xs12>
          <v-alert :value="true" color="blue" icon="apps" outlined>Bem vindo ao módulo Administrativo.</v-alert>
          <v-alert :value="true" color="green" outlined>
            Versão 2.0.1 - 04/05/2024:
            <br />- Módulo inicial;
          </v-alert>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["POSSUI_DIREITO"])
  },
  beforeMount() {
    this.POSSUI_DIREITO();
  }
};
</script>
<style></style>
