var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md7":""}},[_c('v-checkbox',{attrs:{"label":_vm.naoVisualizadas ? 'Somente mensagens não visualizadas' : 'Todas as mensagens'},on:{"change":_vm.limpaFiltro},model:{value:(_vm.naoVisualizadas),callback:function ($$v) {_vm.naoVisualizadas=$$v},expression:"naoVisualizadas"}})],1),_c('v-flex',{attrs:{"xs1":"","offset-md-1":"","md1":""}},[_c('v-btn',{attrs:{"color":"blue lighten-2","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"white","title":"Atualizar"},on:{"click":_vm.carregarMensagens}},[_vm._v("refresh")])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.mensagens,"hide-default-header":"","hide-default-footer":"","no-data-text":"- Não há mensagens -","height":"510"},scopedSlots:_vm._u([{key:"item.data",fn:function({ item }){return [_c('span',{class:item.visualizado ? 'grey--text' : '',domProps:{"innerHTML":_vm._s(_vm.formatDateTime(item.data))}})]}},{key:"item.resumo",fn:function({ item }){return [_c('span',{class:item.visualizado ? 'grey--text' : '',domProps:{"innerHTML":_vm._s(
              '<div style=\'margin-top: 15px;' +
              (item.resumo.includes('Aguarde') || item.resumo.toUpperCase().includes('ERRO') || item.titulo.includes('Erro') ? ' color: red;' : '') +
              '\'><h4>' +
              item.titulo +
              (item.dtMovimento ? ' ' + _vm.formatDate(item.dtMovimento) : '') +
              '</h4><p>' +
              item.resumo +
              '</p>' +
              (_vm.idUsuario ? '' : '<p>' + item.usuario + '</p>')
            )}})]}},{key:"item.visualizado",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.cliqueMensagem(item)}}},[_c('v-icon',{attrs:{"color":item.visualizado ? 'grey' : 'teal darken-2'}},[_vm._v(_vm._s(_vm.mensagemIcone[item.tipo]))])],1)]}}])})],1),_c('v-flex',{staticStyle:{"margin-top":"20px","padding-bottom":"20px"},attrs:{"text-xs-center":""}},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }