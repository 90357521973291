export default {
  //bem vindo
  bemvindo: `ao nosso refúgio junto à natureza<br />
em um espaço que harmoniza<br />
sustentabilidade,<br />
história, cultura e arte<br />
numa experiência<br />
simples e revigorante.`,
  //sobre
  sobre1: `O Espaço começou a ser estruturado em 2010 e cada detalhe foi pensado cuidadosamente e tem um significado. Os espaços foram construídos usando técnicas de Bioconstrução*, com o aproveitamento dos recursos naturais abundantes no local e a buscando causar o menor impacto possível ao ambiente local.`,
  sobre2: `Paralelamente, começamos a cuidar da infraestrutura alimentar: plantação de frutíferas, ervas aromáticas, temperos e chás, juntamente com a criação de uma estufa para folhas e verduras, seguindo os princípios da Permacultura**, o que assegura uma alimentação orgânica e saudável.`,
  sobre3: `Viver no Espaço Mossoo requer uma observação constante e profunda da natureza, compreendendo sua cadência e respeitando o seu ritmo. Viver em harmonia com tudo isso inevitavelmente demanda uma mudança de vida e foi assim que o sentido do significado da palavra <i>slow life</i> e simplicidade ganharam verdadeiro significado para nós.
Se você quer uma experiência do simples, do cuidado e do natural, venha viver uma experiência no Espaço Mossoo.`,
  sobre4: `<p>*Bioconstrução: técnicas de construção que buscam harmonizar edificações com o meio ambiente, utilizando materiais sustentáveis e práticas amigáveis à natureza. Essa abordagem visa criar espaços que respeitam ecossistemas e promovem a sustentabilidade.</p>
<p>**Permacultura: abordagem de design sustentável que se inspira em padrões e características observados na natureza. Incorpora princípios éticos e diretrizes de design para criar ambientes produtivos e sustentáveis, integrando harmoniosamente agricultura, habitação, energia e ecologia. O objetivo é estabelecer sistemas que sejam regenerativos e respeitem os limites ecológicos do planeta.</p>`,
  //blog
  blog: `Aqui você encontrará muitas informações, dicas, história, cultura, arte e curiosidades que cercam de charme esse recanto que escolhemos para viver e compartilhar com quem nos visita.`,
  bambu_bamboo1: `<p>O bambu possui uma rica história e é repleto de simbolismos em várias culturas ao redor do mundo. Em muitas sociedades asiáticas, o bambu é símbolo de flexibilidade e resistência. Sua capacidade de se curvar sem quebrar representa adaptabilidade e tenacidade diante dos desafios.</p>
<p>Na China, o bambu é associado à longevidade, prosperidade e integridade. Em algumas tradições, o bambu também é considerado um símbolo de humildade, pois cresce em abundância, mas muitas vezes em locais simples e discretos.</p>
<p>No Japão, o bambu é apreciado por sua beleza simples e é frequentemente associado à pureza e elegância. A arte japonesa do ikebana (arranjo floral) muitas vezes inclui o uso de hastes de bambu.</p>
<p>Além disso, o bambu desempenhou um papel importante na história da construção em várias partes do mundo, sendo utilizado em pontes, edifícios e utensílios. Sua versatilidade e sustentabilidade continuam a torná-lo uma escolha valiosa em muitos aspectos da vida cotidiana.</p>
<p>Esses simbolismos e usos variados contribuem para a riqueza cultural e histórica associada ao bambu.</p>`,
  bambu_bamboo2: `<p>Existem mais de 200 espécies de bambus no Brasil, alguns são nativos como o taquaruçu (Guadua tagoara), ou taquara gigante.</p>
<p>Ao redor do mundo, são mais de 1.200 espécies, sendo geralmente classificadas em duas categorias principais: "bambus lenhosos" e "bambus herbáceos".</p>
<ol>
  <li>Bambus Lenhosos:</li>
  <ul>
    <li>Phyllostachys: Comuns na construção civil, paisagismo e fabricação de móveis.</li>
    <li>Bambusa: Espécies tropicais, frequentemente usadas em paisagismo e artesanato.</li>
    <li>Dendrocalamus: Mais robustos, usados para construção civil e fabricação de móveis.</li>
  </ul>
  <li>Bambus Herbáceos:</li>
  <ul>
    <li>Pleioblastus e Sasa: Menores em altura, frequentemente utilizados para cobertura do solo ou bordas de jardins.</li>
    <li>Arundinaria: Comum nas Américas, muitas vezes chamado de "bambu nativo".</li>
  </ul>
</ol>
<p>Além dessa divisão, há muitas espécies específicas dentro de cada grupo, cada uma com características únicas em termos de tamanho, resistência e aparência. A escolha do tipo de bambu depende do uso desejado, clima local e preferências estéticas.</p>`,
  bambu_bamboo3: `<p>O nome inicial de nosso espaço seria Bambus, por sua versatilidade e símbolo de sustentabilidade, todavia, é um nome já muito utilizado e não estava disponível. Buscamos a espécie de bambu que poderiamos usar como nome de nosso espaço mantendo a essência do nosso projeto. Chegamos assim ao Mosso, e ajustando a pronúncia, <b>Mossoo</b>.</p>
<p>Abaixo alguns bambus que fazem parte de nosso projeto e dão seus nomes aos nossos espaços:</p>
<p><b>Mosso</b>, refere-se à espécie Phyllostachys edulis, uma variedade de bambu gigante que é nativa da China. É conhecido por seu rápido crescimento e é uma das maiores espécies de bambu do mundo, podendo atingir alturas superiores a 20 metros.</p>
<p><b>Gracilis</b>, Phyllostachys gracilis, também conhecido como "bambu gracioso" ou "bambu esbelto". O gracilis pode atingir até 10 metros ou mais, suas hastes são finas e eretas, dando-lhe uma aparência elegante e grácil. Suas folhas são estreitas e frequentemente verdes, criando uma cobertura visualmente atraente. É frequentemente escolhido para paisagismo ornamental, como uma barreira visual em jardins ou como parte de projetos de design de exteriores.</p>
<p><b>Hatiku</b>, Phyllostachys nigra (Bambu Preto), nativo do Japão, é conhecido por suas hastes verdes escurecendo para preto com o tempo. É apreciado por suas propriedades decorativas e é usado em paisagismo e jardinagem.</p>
<p><b>Vittata</b>, Bambusa vulgaris (Bambu Imperial), é uma espécie ornamental e possui varas na cor amarela com listras verdes. De rápido crescimento, pode atingir até 12 metros de altura na fase adulta, ideal para a construção de cerca viva.</p>`,
  araucaria1: `<p><i>Araucaria angustifolia</i>, popularmente conhecida como pinheiro brasileiro, é uma espécie de conífera endêmica da Região Sul do Brasil, pertence à família <i>Araucariaceae</i> e é uma árvore perene, apresentando folhas persistentes, finas e lanceoladas. Suas estruturas reprodutivas são cones masculinos e femininos, sendo esta última responsável pela produção de sementes.</p>
<p>É encontrada principalmente em altitudes mais elevadas, destacando-se nas florestas de araucária na Região Sul do Brasil, incluindo a Serra Catarinense. O clima ameno dessas áreas montanhosas favorece seu crescimento.</p>
<p>As florestas de araucária desempenham um papel crucial na conservação da biodiversidade. A <i>angustifolia</i> oferece habitat para diversas espécies, sendo especialmente importante para a fauna, incluindo aves e mamíferos.</p>
<p>No entanto, atividades humanas predatórias, ameaçam a sobrevivência dessa espécie. A conservação se torna imperativa para garantir a preservação não apenas da araucária, mas de todo o ecossistema associado.</p>
<p>Além de sua importância ecológica, a mata de araucária é culturalmente rica. Ela está entrelaçada com as tradições locais, refletindo-se em mitos, rituais e festivais. A extração sustentável dos pinhões, por exemplo, é mais do que uma prática econômica; é um elo entre as comunidades e a natureza.</p>`,
  araucaria2: `Nós, do <b>Espaço Mossoo</b>, assumimos o compromisso de perpetuar a araucária em nossas terras. Cientes de seu valor natural, histórico, cultural e social, sentimos falta de sua beleza e começamos a produção e plantio de mudas há mais de 10 anos. Hoje contamos com mudas de araucárias jovens em nosso terreno e continuamos com dois projetos:`,
  araucaria2_1: `1. Plantio de mudas de araucária, produzidas aqui mesmo em nossa estufa, pelos nossos hóspedes. Além do contato e aproximação de nossos clientes com a espécie, garantimos a neutralização de carbono decorrente de sua viagem, visto que praticamente 100% dos clientes viajam de carro e moto.`,
  araucaria2_2: `2. Após contato com o Estudo da UFPR, sobre a araucária precoce e a possibilidade de termos os frutos da araucária, o pinhão, num periodo de aproximadamente seis anos, adquirimos algumas mudas e estamos testando a adaptação ao local para então fazermos uma plantação maior dessa espécie tão versátil e significativa na nossa região.`,
  //hospedagem
  hospedagem: `A proposta do espaço é oferecer hospedagem em contato com a natureza, com um bom sono de reposição das energias, um bom café da manhã preparado valorizando a produção local e orgânica.<br />
As edificações trazem referências da colonização de Santa Catarina em uma homenagem às origens e vivências de seus moradores.`,
  gracillis: `A Cabana Gracillis traz uma referência às cidades de colonização alemã, com muito verde e todo o cuidado em cada detalhe. O nome vem do bambu Gracillis, ornamental muito utilizado no paisagismo.<br />
Sustentando a proposta de proporcionar descanso, relaxamento e uma relação saudável com a alimentação e produção local, a cabana oferece quarto com cama <i>king size</i>, banheiro com banheira de hidromassagem para casal, sendo equipada para receber casais.`,
  //ceramica/bambu
  bambu: `O Bambu foi escolhido como nossa planta símbolo, ele cresce rápido, é versátil e uma escolha sustentável e <i>eco-friendly</i> para construção, mobiliário, decoração, utilitários e até mesmo papel.<br />
Ele foi utilizado na construção da casa principal, e, por sua versatilidade, suas referências estão em cada olhar, como em peças de artesanato e composições com cerâmica artesanal.`,
  ceramica: `2023 começou prometendo grandes realizações.<br /> 
O sonho e o desejo de expressar a paixão pela gastronomia através da cerâmica. Do contato com a cultura oriental, herdei a paixão pelo simples, o belo e a atenção aos detalhes.<br />
Logo, logo teremos muitas novidades por aqui.`,
  //experiências
  experiencias: `<p>Internamente, além de cabana para hospedagem, há a casa principal com estrutura de cozinha com possibilidade de refeições ou para preparo de alimentação utilizando temperos e ervas orgânicas produzidas no local.</p>
<p>O ambiente é propício para a prática de caminhadas, trilhas e passeios, meditação ou simplesmente só estar ao ar livre ouvindo os pássaros e o som das águas. Há bancos e namoradeiras por todo o espaço.</p>
<p>Oficina de cerâmica: dispomos de experiência para iniciantes ou como terapia, com manuseio de argila.</p>
<p>A região é cercada por lindas paisagens, possui estrutura de restaurantes, parque aquático e comércio local.</p>
<p>Já a Serra Catarinense é deslumbrante e apresenta muitas opções de passeios e experiências nas vinícolas, Serra do Rio do Rastro, Serra do Corvo Branco, Mirante da Boa Vista, Urubici, a neve que presenteia a região nos dias mais frios.</p>
<p>Para quem gosta de aventura, a região é muito procurada para a prática de ciclismo, motocross, enduros, cavalgadas e visitação de cachoeiras.</p>
<p>Para os amantes da natureza, história e artes rupestres, a região é riquíssima em legados de colonizações passadas que deixaram suas heranças nas inscrições rupestres, costumes, fazeres e áreas preservadas, fauna e flora que tornarão o passeio inesquecível e enriquecedor.</p>
<p>Não faltam atividades, mas a sugestão é só relaxar e aproveitar o ambiente convidativo para desacelerar.</p>
<p>Acesse o nosso blog, traremos novidades e dicas para aproveitar a Serra Catarinense.</p>`,
  //contato
  contato: `Caso tenha alguma dúvida e ficou interessado no que está acontecendo por aqui, entre em contato conosco. Será uma grande satisfação atendê-lo.`,

  itensAministrativo: [
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Acessórios",
      model: false,
      children: [
        {
          icon: "language",
          text: "Países",
          link: "/administrativo/paises",
          acesso: true
        },
        {
          icon: "landscape",
          text: "Estados",
          link: "/administrativo/ufs",
          acesso: true
        },
        {
          icon: "location_city",
          text: "Municípios",
          link: "/administrativo/municipios",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Configuração",
      model: false,
      children: [
        {
          icon: "local_cafe",
          text: "Feriados",
          link: "/administrativo/feriados",
          acesso: true
        },
        {
          icon: "home",
          text: "Cabanas",
          link: "/administrativo/cabanas",
          acesso: true
        },
        {
          icon: "arrow_downward",
          text: "Origens",
          link: "/administrativo/origens",
          acesso: true
        },
        {
          icon: "grid_on",
          text: "Tabelas",
          link: "/administrativo/tabelas",
          acesso: true
        },
        {
          icon: "deck",
          text: "Kits",
          link: "/administrativo/kits",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Operação",
      model: false,
      children: [
        {
          icon: "tag_faces",
          text: "Clientes",
          link: "/administrativo/clientes",
          acesso: true
        },
        {
          icon: "shopping_cart",
          text: "Reservas",
          link: "/administrativo/reservas",
          acesso: true
        },
        {
          icon: "event",
          text: "Mapa",
          link: "/administrativo/mapa",
          acesso: true
        }
      ]
    },
    {
      icon: "keyboard_arrow_up",
      "icon-alt": "keyboard_arrow_down",
      text: "Relatórios",
      model: false,
      children: [
        {
          icon: "view_list",
          text: "Reservas",
          link: "/administrativo/relatorio/reservas",
          acesso: true
        }
      ]
    }
  ]
};
