<!-- xs sm md lg xl -->
<template>
  <v-container grid-list-md :style="$vuetify.breakpoint.xl ? 'max-width: 70%' : $vuetify.breakpoint.smAndDown ? 'max-width: 95%' : ''">
    <v-layout wrap>
      <v-flex xs10>
        <v-img class="img-responsive" src="logo.png" :max-width="$vuetify.breakpoint.smAndDown ? 120 : 180"></v-img>
      </v-flex>
      <v-flex xs2 class="text-right">
        <v-img
          :style="`float: right; margin-right: ${$vuetify.breakpoint.smAndDown ? '-20px;' : '-30px'};`"
          class="img-responsive"
          src="folhinha.png"
          :max-width="$vuetify.breakpoint.smAndDown ? 80 : 120"
        ></v-img>
      </v-flex>

      <v-flex xs12 v-if="$vuetify.breakpoint.smAndUp">
        <v-tabs v-model="tab" class="white" center-active show-arrows slider-color="#457d30" color="#457d30">
          <v-tab v-for="(item, i) in menu" :key="i" @click="click(i)">
            <span class="p12"
              ><b>{{ menu[i] }}</b></span
            >
          </v-tab>
        </v-tabs>
      </v-flex>

      <v-flex xs12>
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-navigation-drawer v-model="drawer" absolute left>
          <v-list nav dense>
            <v-list-item-group v-model="tab">
              <v-list-item v-for="(item, i) in menu" :key="i" @click="click(i)">
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="p12">{{ menu[i].toUpperCase() }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-flex>

      <v-flex xs12>
        <v-tabs-items v-model="tab">
          <v-tab-item active>
            <BemVindo />
          </v-tab-item>

          <v-tab-item>
            <Sobre />
          </v-tab-item>

          <v-tab-item>
            <Blog />
          </v-tab-item>

          <v-tab-item>
            <Hospedagem :reservas.sync="reservas" :contato.sync="contato" />
          </v-tab-item>

          <v-tab-item>
            <Artesanato />
          </v-tab-item>

          <v-tab-item>
            <Experiencias />
          </v-tab-item>

          <v-tab-item>
            <Reserva :contato.sync="contato" />
          </v-tab-item>

          <v-tab-item>
            <Contato />
          </v-tab-item>

          <v-tab-item>
            <SuporteGas v-if="tokenGas" />
            <span class="p12" v-if="!tokenGas">Área restrita!</span>
          </v-tab-item>

          <v-tab-item>
            <SuporteInverno v-if="tokenInv" />
            <span class="p12" v-if="!tokenInv">Área restrita!</span>
          </v-tab-item>

          <v-tab-item>
            <SuporteCelesc v-if="tokenCel" />
            <span class="p12" v-if="!tokenCel">Área restrita!</span>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>

      <v-dialog persistent v-model="dialogToken" max-width="600px" @keydown.esc="dialogToken = false">
        <v-card>
          <v-form ref="formToken" v-model="tokenValid" lazy-validation>
            <v-toolbar>
              <span class="p20">Acesso</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialogToken = false">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-text-field
                      class="p20"
                      autofocus
                      v-model="msgToken"
                      label="Informe o Token"
                      maxlength="32"
                      :rules="[(value) => valida_token(value) || 'Token inválido']"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      hint="Cole aqui o token recebido"
                      persistent-hint
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="validar">Acessar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import BemVindo from "@/views/BemVindo.vue";
import Sobre from "@/views/Sobre.vue";
import Blog from "@/views/Blog.vue";
import Hospedagem from "@/views/Hospedagem.vue";
import Artesanato from "@/views/Artesanato.vue";
import Experiencias from "@/views/Experiencias.vue";
import Reserva from "@/views/Reserva.vue";
import Contato from "@/views/Contato.vue";
import SuporteGas from "@/views/SuporteGas.vue";
import SuporteInverno from "@/views/SuporteInverno.vue";
import SuporteCelesc from "@/views/SuporteCelesc.vue";

export default {
  components: {
    BemVindo,
    Sobre,
    Blog,
    Hospedagem,
    Artesanato,
    Experiencias,
    Reserva,
    Contato,
    SuporteGas,
    SuporteInverno,
    SuporteCelesc
  },
  data() {
    return {
      drawer: false,
      tab: 0,
      menu: ["Bem Vindo", "Sobre Nós", "Blog", "Hospedagem", "Cerâmica/Bambu", "Experiências", "Reserva", "Contato", "", "", ""],
      tokenValid: true,
      dialogToken: false,
      tokenGas: false, //somente gás
      tokenInv: false, //gás e celesc (aquecedor)
      tokenCel: false, //somente celesc
      token: "Q0xJRU5uxX0lEOkNmSUVoVF9TRUNSRVq",
      senhas: ["mossoo599", "mossoo699", "mossoo799"],
      msgToken: "",
      reservas: false,
      contato: false
    };
  },
  methods: {
    click(tab) {
      if (this.tab == tab && this.$vuetify.breakpoint.xl) {
        this.tab = 0;
      }
      setTimeout(() => {
        this.tab = tab;
      }, 300);
      this.drawer = false;
      if (tab == 8 && !this.tokenGas) {
        this.dialogToken = true;
      }
      if (tab == 9 && !this.tokenInv) {
        this.dialogToken = true;
      }
      if (tab == 10 && !this.tokenCel) {
        this.dialogToken = true;
      }
    },
    valida_token(token) {
      var retorno = false;
      if (token == this.senhas[0]) {
        this.tokenGas = true;
        this.menu[8] = "Instruções";
        this.tab = 8;
        retorno = true;
      } else if (token == this.senhas[1]) {
        this.tokenInv = true;
        this.menu[9] = "Instruções";
        this.tab = 9;
        retorno = true;
      } else if (token == this.senhas[2]) {
        this.tokenCel = true;
        this.menu[10] = "Instruções";
        this.tab = 10;
        retorno = true;
      }
      if (retorno) {
        this.dialogToken = false;
      }
      return retorno;
    },
    validar() {
      if (!this.$refs.formToken.validate()) {
        return;
      }
      this.dialogToken = false;
    }
  },
  watch: {
    reservas(val) {
      if (val) {
        this.tab = 6;
        this.reservas = false;
      }
    },
    contato(val) {
      if (val) {
        this.tab = 7;
        this.contato = false;
      }
    }
  },
  created() {
    if (this.$route.query.token == this.token) {
      this.dialogToken = true;
    } else if (this.$route.query.hasOwnProperty("sobre")) {
      this.tab = 1;
    } else if (this.$route.query.hasOwnProperty("blog")) {
      this.tab = 2;
    } else if (this.$route.query.hasOwnProperty("hospedagem")) {
      this.tab = 3;
    } else if (this.$route.query.hasOwnProperty("artesanato")) {
      this.tab = 4;
    } else if (this.$route.query.hasOwnProperty("experiencias")) {
      this.tab = 5;
    } else if (this.$route.query.hasOwnProperty("reserva")) {
      this.tab = 6;
    } else if (this.$route.query.hasOwnProperty("contato")) {
      this.tab = 7;
    }
  }
};
</script>

<style></style>
