<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Contato</p>
      </v-flex>

      <v-flex offset-md4 md4 xs12 class="text-center">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" style="color: #065e2f" v-html="$store.state.contato"></p>
      </v-flex>

      <v-flex xs12 class="text-center" style="margin-top: 20px">
        <v-btn icon href="mailto:mossooespaco@gmail.com" :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''">
          <v-icon v-if="$vuetify.breakpoint.xs" title="Email" color="#5b361b">mail</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large title="Email" color="#5b361b">mail</v-icon>
        </v-btn>

        <v-btn :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''" icon href="https://www.facebook.com/espacomossoo" target="_blank">
          <v-icon v-if="$vuetify.breakpoint.xs" title="Facebook" color="#3b5998">mdi-facebook</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large title="Facebook" color="#3b5998">mdi-facebook</v-icon>
        </v-btn>

        <v-btn :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''" icon href="https://www.instagram.com/espacomossoo/" target="_blank">
          <v-icon v-if="$vuetify.breakpoint.xs" title="Instagram" color="#517fa4">mdi-instagram</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large title="Instagram" color="#517fa4">mdi-instagram</v-icon>
        </v-btn>

        <v-btn :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''" icon href="https://br.pinterest.com/mossooespaco/" target="_blank">
          <v-icon v-if="$vuetify.breakpoint.xs" title="Pinterest" color="#E60023">mdi-pinterest</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large title="Pinterest" color="#E60023">mdi-pinterest</v-icon>
        </v-btn>

        <!-- <v-btn :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''" icon disabled>
          <v-icon v-if="$vuetify.breakpoint.xs" disabled title="Youtube" color="#bb0000">mdi-youtube</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large disabled title="Youtube" color="#bb0000">mdi-youtube</v-icon>
        </v-btn> -->

        <v-btn
          :style="$vuetify.breakpoint.smAndUp ? 'margin: 0 20px 0 20px' : ''"
          icon
          href="https://api.whatsapp.com/send/?phone=+5548999823931&text&type=phone_number&app_absent=0&text=Olá Espaço Mossoo..."
          target="_blank"
        >
          <v-icon v-if="$vuetify.breakpoint.xs" title="Whatsapp" color="#34af23">mdi-whatsapp</v-icon>
          <v-icon v-if="$vuetify.breakpoint.smAndUp" x-large title="Whatsapp" color="#34af23">mdi-whatsapp</v-icon>
        </v-btn>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '80px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
