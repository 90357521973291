<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'">Instruções da Televisão</p>
      </v-flex>
      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/controleRemoto.png" :height="$vuetify.breakpoint.xs ? 100 : 350"> </v-img>
      </v-flex>
      <v-flex xs12 align-center style="padding: 0px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Ao ligar o televisor, você terá acesso aos canais livres da Sansung TV - para mudar de canal, utilize as setas CH;<br />
          Pressionando o botão <v-img style="display: inline-block" class="img-responsive" src="hospedagem/botaoHome.png" :width="$vuetify.breakpoint.xs ? 25 : 40"> </v-img> você
          poderá selecionar entre diversos aplicativos. Para isso, utilize as setas e selecione com o botão entre elas.<br />Atualmente temos liberados: Netflix e Spotify.
          Divirta-se!
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
