<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'" id="banheira">Instruções da Banheira de Hidromassagem</p>
      </v-flex>

      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/enchendo.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">1. Feche a tampa no fundo da banheira;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/torneira.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">2. Abra totalmente a torneira (para cima e para a esquerda);</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/limite.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">3. Feche a torneira ao cobrir os discos de inox laterais (uns 60 minutos);</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/ligando.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">4. Ligue a banheira;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/circular.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">5. Ligue o circulador e coloque os sais e espuma de banho;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/aumentando.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">6. Ajuste a temperatura - sugestão: 36ºC. Obs: valor superior a 38ºC pode causar <b>hipertermia*</b>;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/tampa.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">7. Cubra o painel para escurecer o ambiente;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/enchendo.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">8. Tome uma ducha e entre pelo próprio box;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/circular.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">9. Ao terminar, desligue o circulador;</p>
          </v-flex>

          <br />
          <v-flex xs6 md3 align-center>
            <v-img class="img-responsive" src="hospedagem/hidro/ligando.png" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">10. Quando parar de circular, desligue a banheira;</p>
          </v-flex>

          <v-flex xs12 align-center>
            <p :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">
              *Hipertermia: Pode ocorrer devido a imersão prolongada em água quente, quando a temperatura interna do corpo alcança um nível mais alto que a temperatura normal
              (37°C).<br />Sintomas: sonolência, letargia e aumento na temperatura interna do corpo. <br />Efeitos: corpo passa a não perceber mais o calor, não reconhecimento da
              necessidade de sair da banheira, inabilidade física para sair da banheira, perigo fatal em mulheres grávidas e inconsciência com perigo de se afogar.
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
