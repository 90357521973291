<template>
  <v-card class="hide-overflow fill-height">
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">dashboard</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Diárias</v-toolbar-title>
    </v-toolbar>

    <div class="px-6 mt-4">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            autofocus
            v-model="idCabana"
            :disabled="carregando"
            :filter="filtrarObjetos"
            :items="cabanas"
            hint="Obrigatório"
            :rules="[(value) => !!value || 'Obrigatório.']"
            label="Selecione a Cabana"
            item-text="nome"
            item-value="id"
            no-data-text
            @change="pesquisaDados"
            autocomplete="disabled"
            :name="Math.random()"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-progress-linear :active="carregando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">Hoje</v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            locale="pt-BR"
            ref="calendar"
            v-model="focus"
            type="month"
            color="primary"
            :events="registros"
            :event-color="getEventColor"
            :event-height="40"
            @click:event="showEvent"
            @click:date="diaSelecionado"
            @change="updateRange"
            style="text-align: center"
          ></v-calendar>

          <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>deck</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p14'">
                Origem: <b>{{ selectedEvent.origem }}</b
                ><br />
                Preço: <b>{{ formatCurrency(selectedEvent.vlrVenda) }}</b
                ><br />
                Pago: <b>{{ formatCurrency(selectedEvent.vlrPago) }}</b
                ><v-icon
                  :title="selectedEvent.vlrPago >= selectedEvent.vlrVenda ? 'Quitado' : 'Não quitado'"
                  class="ml-2"
                  :color="selectedEvent.vlrPago >= selectedEvent.vlrVenda ? 'green' : 'red'"
                  >{{ selectedEvent.vlrPago >= selectedEvent.vlrVenda ? "monetization_on" : "money_off" }}</v-icon
                ><br />
                Entrada: <b>{{ formatDate(selectedEvent.dtEntrada) }} 15hs</b><br />
                Saída: <b>{{ formatDate(selectedEvent.dtSaida) }} 12hs</b><br />
                E-mail: <b>{{ selectedEvent.email }}</b
                ><br />
                Município: <b>{{ selectedEvent.municipio }}</b
                ><br />
                Telefone: <b>{{ selectedEvent.telefone }}</b>
              </v-card-text>
              <v-card-actions>
                <v-btn text outlined color="orange white" style="color: white" @click="selectedOpen = false"> Fechar </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      filtroPesquisa: "",
      registros: [],
      idCabana: null,
      cabanas: [],
      carregando: true,
      mensagem: "",
      snackErro: false,
      mes: 0,
      ano: 0,

      focus: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      if (event.origem) {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      } else {
        //TODO: abrir tela (ou dialog) para cadastro da diária
        // console.log(this.idCabana, event.start);
      }
    },
    updateRange({ start, end }) {
      var dtInicio = util.parseDateUS(start.date);
      this.mes = dtInicio.getMonth() + 1;
      this.ano = dtInicio.getFullYear();

      this.pesquisaDados();
    },
    diaSelecionado(date) {
      console.log(date.date);
    },
    async pesquisaDados() {
      if (!this.idCabana || !this.mes || !this.ano) {
        return;
      }

      //sinaliza que está carregando
      this.carregando = true;
      this.registros = [];

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(`${process.env.VUE_APP_URL_WEB_API}adm/mapa?idCabana=${this.idCabana}&mes=${this.mes}&ano=${this.ano}`);
        if (response.data && response.data.length) {
          //seta as variaveis com o retorno
          const elementos = response.data;
          var data = null;
          for (let index = 0; index < elementos.length; index++) {
            var element = elementos[index];

            if (!element.dtEntrada) {
              element.dtEntrada = util.parseDateUS(element.data);
            } else {
              element.dtEntrada = util.parseDateUS(element.dtEntrada);
            }
            if (!element.dtFim) {
              element.dtFim = util.parseDateUS(element.data);
            } else {
              element.dtFim = util.parseDateUS(element.dtFim);
            }

            var dtSaida = util.parseDateUS(element.dtFim);
            dtSaida.setDate(dtSaida.getDate() + 1);
            element.dtSaida = util.dateToStringUS(dtSaida);

            var sdf = false;
            if (!data) {
              data = util.parseDateUS(element.data);
            }
            if (element.dtFim < data) {
              continue;
            }

            var vlrVenda = 0;
            var x = index + 0;
            do {
              if (element.vlrVenda) {
                vlrVenda = element.vlrVenda;
              } else {
                vlrVenda += elementos[x].vlrMossoo;
              }

              if (elementos[x].feriado) {
                sdf = true;
              }
              if (data.getDay() == 0 || data.getDay() == 6) {
                sdf = true;
              }

              x++;
              data.setDate(data.getDate() + 1);
            } while (data <= element.dtFim);

            this.registros.push({
              name:
                (element.cliente ? element.cliente + " - " : "") +
                util.formatCurrency(vlrVenda) +
                (element.feriado && this.$vuetify.breakpoint.lgAndUp ? " - " + element.feriado : ""),
              start: element.dtEntrada,
              end: element.dtFim,
              color: element.cliente
                ? element.vlrPago
                  ? sdf
                    ? "red darken-3"
                    : "red lighten-1"
                  : sdf
                  ? "orange darken-3"
                  : "orange"
                : sdf
                ? "green darken-3"
                : "green lighten-1",
              timed: false,

              origem: element.origem,
              feriado: element.feriado,
              vlrVenda: vlrVenda,
              vlrPago: element.vlrPago,
              dtEntrada: util.dateToStringUS(element.dtEntrada),
              dtSaida: util.dateToStringUS(element.dtSaida),
              email: element.email,
              municipio: element.municipio ? element.municipio + "/" + element.uf : "",
              telefone: element.telefone,
              kits: element.kits
            });
          }
        } else {
          this.mensagem = "Período ainda indisponível";
          this.snackErro = true;
        }

        //fecha o loading
        this.carregando = false;
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
        } else {
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    },
    formatDate(date) {
      return util.formatDate(date);
    }
  },

  async beforeMount() {
    this.POSSUI_DIREITO();

    const responseCabanas = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cabana/lista");
    this.cabanas = responseCabanas.data;
    this.idCabana = this.cabanas[0].id;
    this.pesquisaDados();
  }
};
</script>
