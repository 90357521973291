<template>
  <v-app-bar color="#065e2f" dark app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed>
    <v-toolbar-title style="width: 400px" class="ml-0">
      <v-app-bar-nav-icon @click.stop="MOSTRAR_OCULTAR_MENU"></v-app-bar-nav-icon>
      <span class="hidden-sm-and-down font-weight-light">Espaço Mossoo {{ $store.state.versao }} | {{ $store.state.modulo }}</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn icon @click="dialogMensagensAbrir()">
      <v-badge color="red" left v-if="mensagensCarregadas > 0" :content="mensagensCarregadas"></v-badge>
      <v-icon title="Mensagens">mail</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon @click="deslogar" title="Sair">open_in_new</v-icon>
    </v-btn>

    <router-link :to="'/administrativo/usuario/' + $store.state.usuario.id">
      <v-icon :alt="$store.state.usuario.nome" :title="$store.state.usuario.nome">account_box</v-icon>
    </router-link>

    <v-dialog v-model="dialogMensagens" max-width="900px">
      <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
        <v-card-title class="headline grey lighten-2" primary-title>Mensagens</v-card-title>
        <v-card-text>
          <ListaMensagens :mensagensCarregadas.sync="mensagensCarregadas" />
        </v-card-text>
        <v-card-actions class="flex-wrap">
          <v-btn color="orange lighten-2 white" style="color: white" class="btn-rsn" @click="dialogMensagensFechar">
            <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapMutations } from "vuex"; //importa funcão do vuex para importar as mutations do store
import ListaMensagens from "@/views/components/ListaMensagens.vue";

export default {
  components: {
    ListaMensagens
  },
  data() {
    return {
      moduloADM: false,
      dialogMensagens: false,
      mensagensCarregadas: 0,
      primeiraVez: true
    };
  },
  methods: {
    ...mapMutations(["MOSTRAR_OCULTAR_MENU"]), //Mostrar/esconder menu
    async deslogar() {
      try {
        await this.$store.dispatch("deslogarUsuario"); //loga o usuario
      } catch (e) {
        return;
      }
    },
    dialogMensagensFechar() {
      this.dialogMensagens = false;
    },
    dialogMensagensAbrir() {
      this.dialogMensagens = true;
      if (this.primeiraVez) {
        setTimeout(() => {
          this.primeiraVez = false;
          this.dialogMensagens = false;
        }, 1);
      }
    }
  },
  beforeMount() {
    this.moduloADM = true;
    this.dialogMensagensAbrir();
  },
  watch: {
    mensagensCarregadas() {}
  }
};
</script>

<style></style>
