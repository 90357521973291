<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Reserva</p>
      </v-flex>

      <v-flex xs12 style="margin-top: 20px" v-if="!reservaON">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex offset-md4 md4 xs12 class="text-center">
              <v-icon>sentiment_very_satisfied</v-icon>
              <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" style="color: #065e2f" v-html="'Em breve, você poderá realizar sua reserva diretamente aqui.'"></p>
              <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
                Por enquanto, <a class="estilo-link-site" @click="funcContato">entre em contato conosco</a> ou também estamos:
              </p>
              <br />
            </v-flex>

            <v-flex xs12></v-flex>
            <v-flex md4 xs3 style="padding: 0px; margin-top: -30px"></v-flex>
            <v-flex md2 xs3 style="margin-top: -30px" class="text-left">
              <a href="http://airbnb.com/h/gracillis-espacomossoo" target="_blank">
                <v-img class="img-responsive" src="hospedagem/airbnb.png" :width="$vuetify.breakpoint.xs ? 70 : 120"> </v-img>
              </a>
            </v-flex>
            <v-flex md2 xs3 style="margin-top: -10px; margin-left: 0px" class="text-right">
              <script
                type="application/javascript"
                async
                src="https://badge.hotelstatic.com/embed.js"
                data-url="https://www.booking.com/hotel/br/cabana-gracillis.html"
                :data-size="$vuetify.breakpoint.xs ? 40 : 60"
                data-position="inline"
                data-clickable="true"
              ></script>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>

      <v-flex xs12 v-if="reservaON">
        <v-alert :value="true" color="error" dense class="text-center">##### VOCÊ ESTÁ EM UM AMBIENTE DE TESTES #####</v-alert>
        <v-alert v-if="cliente != null" :value="true" color="primary" dense outlined>Olá, {{ cliente }}</v-alert>

        <v-stepper v-model="etapa">
          <v-stepper-header>
            <v-stepper-step :complete="etapa > 1" step="1" :color="etapa > 1 ? 'green lighten-2' : 'primary'"
              >Cabana
              <h5>Selecione sua cabana</h5>
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step :complete="etapa > 2" step="2" :color="etapa > 2 ? 'green lighten-2' : 'primary'"
              >Identificação
              <h5>Informe seus dados</h5></v-stepper-step
            >

            <v-divider></v-divider>
            <v-stepper-step :complete="etapa > 3" step="3" :color="etapa > 3 ? 'green lighten-2' : 'primary'"
              >Check-in
              <h5>Selecione a data de check-in</h5></v-stepper-step
            >

            <v-divider></v-divider>
            <v-stepper-step :complete="etapa > 4" step="4" :color="etapa > 4 ? 'green lighten-2' : 'primary'"
              >Check-out
              <h5>Selecione a data de check-out</h5>
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step :complete="etapa > 5" step="5" :color="etapa > 5 ? 'green lighten-2' : 'primary'"
              >Pagamento
              <h5>Realize o pagamento</h5></v-stepper-step
            >

            <v-divider></v-divider>
            <v-stepper-step :complete="etapa > 5" step="5" :color="etapa > 5 ? 'green lighten-2' : 'primary'"
              >Confirmação
              <h5>Confirmação da Reserva</h5></v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <!-- Selecione a cabana -->
              <v-card class="mb-12">
                <v-card-title v-if="$vuetify.breakpoint.smAndDown">
                  <h3 style="color: blue">Selecione sua cabana</h3>
                  <v-divider></v-divider>
                </v-card-title>
                <v-card-text> <Gracillis></Gracillis></v-card-text
              ></v-card>
              <v-btn color="primary" @click="etapa = 2"> Confirmar </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2"
              ><!-- Confirme seus dados -->
              <v-flex xs12>
                <v-alert :value="true" color="success" dense>Cabana: Gracillis</v-alert>
              </v-flex>

              <v-tabs v-model="tab" v-if="$vuetify.breakpoint.xs">
                <v-tab><v-icon>input</v-icon></v-tab>
                <v-tab><v-icon>account_box</v-icon></v-tab>
                <v-tab><v-icon>find_in_page</v-icon></v-tab>
              </v-tabs>
              <v-flex xs12 offset-md2 md8 v-if="$vuetify.breakpoint.mdAndUp">
                <v-tabs v-model="tab" grow center-active class="white" slider-color="#457d30" color="#457d30">
                  <v-tab
                    ><b>{{ $vuetify.breakpoint.xs ? "Login" : "Já sou cadastrado" }}</b></v-tab
                  >
                  <v-tab
                    ><b>{{ $vuetify.breakpoint.xs ? "Cadastro" : "Quero me cadastrar" }}</b></v-tab
                  >
                  <v-tab
                    ><b>{{ $vuetify.breakpoint.xs ? "Recuperar" : "Recuperar senha" }}</b></v-tab
                  >
                </v-tabs>
              </v-flex>

              <v-flex xs12 offset-md2 md8>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <v-card class="mb-12">
                      <v-form ref="formLogin" v-model="loginValid" lazy-validation>
                        <v-container fluid grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
                              <h3 style="color: blue">Já sou cadastrado</h3>
                              <v-divider></v-divider>
                            </v-flex>

                            <v-flex xs12 md4>
                              <v-text-field
                                autofocus
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="login"
                                maxlength="100"
                                label="Informe seu E-mail ou CPF/CNPJ"
                                hint="Obrigatório"
                                :rules="[validacao.login == false || validacao.login, (value) => !!value || 'Obrigatório.', (value) => valida_email_identificacao(value)]"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @change="validacao.login = false"
                              ></v-text-field>
                            </v-flex>
                            <v-flex md12> </v-flex>

                            <v-flex xs12 md4>
                              <v-text-field
                                autofocus
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="senha"
                                maxlength="20"
                                label="Informe sua Senha"
                                type="password"
                                hint="Obrigatório"
                                :rules="[validacao.senha == false || validacao.senha, (value) => !!value || 'Obrigatório.']"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @change="validacao.senha = false"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card>
                    <v-btn color="primary" @click="acessar"> Acessar </v-btn>
                  </v-tab-item>

                  <v-tab-item active>
                    <v-card class="mb-12">
                      <v-form ref="formNovo" v-model="novoValid" lazy-validation>
                        <v-container fluid grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
                              <h3 style="color: blue">Quero me cadastrar</h3>
                              <v-divider></v-divider>
                            </v-flex>

                            <v-flex xs12 md3>
                              <v-text-field
                                autofocus
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="identificacao"
                                maxlength="14"
                                v-mask="'##############'"
                                label="CPF/CNPJ"
                                hint="Obrigatório"
                                :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value)]"
                                autocomplete="disabled"
                                :name="Math.random()"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 md7>
                              <v-text-field
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="email"
                                maxlength="100"
                                label="E-mail"
                                hint="Obrigatório"
                                :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_email(value)]"
                                autocomplete="disabled"
                                :name="Math.random()"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12>
                              <v-text-field
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="nome"
                                label="Nome do Titular da Reserva"
                                maxlength="100"
                                hint="Obrigatório"
                                :rules="[
                                  (value) => !!value || 'Obrigatório.',
                                  (value) => valida_nome(value) || 'Nome inválido',
                                  (value) => value.length > 4 || 'Preencha o nome do titular'
                                ]"
                                color="blue-grey lighten-2"
                                @change="nome = pLetra(nome)"
                                autocomplete="disabled"
                                :name="Math.random()"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 md5>
                              <h4 class="overline font-italic">Whatsapp</h4>
                              <v-divider></v-divider>
                              <v-layout wrap>
                                <v-flex xs4>
                                  <v-text-field
                                    :disabled="isUpdating"
                                    v-model="ddd"
                                    label="DDD"
                                    v-mask="'##'"
                                    hint="Obrigatório"
                                    :rules="[(value) => !!value || 'Obrigatório.', (value) => isDDDvalido(value)]"
                                    color="blue-grey lighten-2"
                                  ></v-text-field>
                                </v-flex>

                                <v-flex xs8>
                                  <v-text-field
                                    :disabled="isUpdating"
                                    v-model="fone"
                                    label="Número"
                                    v-mask="'###########'"
                                    hint="Obrigatório"
                                    :rules="[(value) => !!value || 'Obrigatório.', (value) => (value != null && value.length > 7) || 'Número inválido']"
                                    color="blue-grey lighten-2"
                                    autocomplete="disabled"
                                    :name="Math.random()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12>
                              <v-sheet class="ml-1 green--text" :value="true" v-if="tokenValidar"><strong>Um código foi enviado para o e-mail informado</strong></v-sheet>
                            </v-flex>
                            <v-flex xs12 v-if="tokenValidar">
                              <v-text-field
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="tokenEmail"
                                maxlength="25"
                                label="Informe o código recebido"
                                autocomplete="disabled"
                                :name="Math.random()"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12>
                              <v-sheet class="ml-1 red--text" :value="true" v-if="mensagemErro">{{ mensagemErro }}</v-sheet>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card>
                    <v-btn disabled color="primary" @click="cadastrar"> {{ tokenValidar ? "Validar Código" : "Confirmar" }} </v-btn>
                  </v-tab-item>

                  <v-tab-item>
                    <v-card class="mb-12">
                      <v-form ref="formEsqueci" v-model="esqueciValid" lazy-validation>
                        <v-container fluid grid-list-md>
                          <v-layout wrap>
                            <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
                              <h3 style="color: blue">Esqueci minha senha</h3>
                              <v-divider></v-divider>
                            </v-flex>

                            <v-flex xs12 md4>
                              <v-text-field
                                autofocus
                                :disabled="isUpdating"
                                background-color="white"
                                v-model="identificacao"
                                maxlength="14"
                                v-mask="'##############'"
                                label="Informe seu CPF/CNPJ"
                                hint="Obrigatório"
                                :rules="[validacao.identificacao == false || validacao.identificacao, (value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value)]"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @change="validacao.identificacao = false"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="logar">
                              <a href="#" @click="tab = 0">Acessar</a>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card>
                    <v-btn :disabled="isUpdating || logar" color="primary" @click="esqueci" center>Recuperar Senha</v-btn>
                  </v-tab-item>
                </v-tabs-items>
              </v-flex>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12">
                <v-alert :value="true" color="success" dense>Cabana: Gracillis</v-alert>
                <v-card-title v-if="$vuetify.breakpoint.smAndDown">
                  <h3 style="color: blue">Selecione a data de check-in</h3>
                  <v-divider></v-divider>
                </v-card-title>
                <v-card-text><Mapa :dtSelecionada.sync="dtSelecionada" :tipo.sync="tipo"></Mapa></v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card class="mb-12">
                <v-alert :value="true" color="success" dense>Cabana: Gracillis</v-alert>
                <v-alert :value="true" color="success" dense>Checkin: {{ formatDate(dtInicio) }} 15hs</v-alert>
                <v-card-title v-if="$vuetify.breakpoint.smAndDown">
                  <h3 style="color: blue">Selecione a data de check-out</h3>
                  <v-divider></v-divider>
                </v-card-title>
                <v-card-text> <Mapa :disabled="isUpdating" :dtSelecionada.sync="dtSelecionada" :tipo.sync="tipo" :dtInicio.sync="dtInicio"></Mapa></v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card class="mb-12">
                <v-form ref="formAdicional" v-model="adicionalValid" lazy-validation>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-alert :value="true" color="success" dense>Cabana: Gracillis</v-alert>
                        <v-alert :value="true" color="success" dense>Checkin: {{ formatDate(dtInicio) }} 15hs</v-alert>
                        <v-alert :value="true" color="success" dense>Checkout: {{ formatDate(dtFim) }} 12hs</v-alert>
                        <v-alert :value="true" color="success" dense>Valor da Reserva: {{ formatCurrency(nroPessoas == 2 ? vlrReserva : vlrReserva * 0.9) }}</v-alert>
                      </v-flex>
                      <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown">
                        <h3 style="color: blue">Dados para pagamento</h3>
                        <v-divider></v-divider>
                      </v-flex>

                      <v-flex xs8 md2>
                        <v-autocomplete
                          :disabled="isUpdating"
                          v-model="formaPgto"
                          :items="[
                            { id: 'CREDIT_CARD', nome: 'Cartão de Crédito' },
                            { id: 'PIX', nome: 'PIX' }
                          ]"
                          hint="Obrigatório"
                          :rules="[(value) => !!value || 'Obrigatório.']"
                          label="Forma de Pagamento"
                          no-data-text
                          autocomplete="disabled"
                          :name="Math.random()"
                          item-text="nome"
                          item-value="id"
                          return-object
                        >
                        </v-autocomplete>
                      </v-flex>

                      <v-flex xs4 md2 v-if="itensParcelas.length > 1">
                        <v-autocomplete
                          :disabled="isUpdating"
                          v-model="nroParcelas"
                          :items="itensParcelas"
                          hint="Obrigatório"
                          :rules="[(value) => !!value || 'Obrigatório.']"
                          label="Parcelas"
                          no-data-text
                          autocomplete="disabled"
                          :name="Math.random()"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12></v-flex>

                      <v-flex xs12 md3 v-if="!existeIdentificacao">
                        <v-text-field
                          autofocus
                          :disabled="isUpdating"
                          background-color="white"
                          v-model="identificacao"
                          maxlength="14"
                          v-mask="'##############'"
                          label="CPF/CNPJ"
                          hint="Obrigatório"
                          :rules="[(value) => !!value || 'Obrigatório.', (value) => valida_identificacao(value)]"
                          autocomplete="disabled"
                          :name="Math.random()"
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs8 md2>
                        <v-autocomplete
                          :disabled="isUpdating"
                          v-model="nroPessoas"
                          :items="[1, 2]"
                          hint="Obrigatório"
                          :rules="[(value) => !!value || 'Obrigatório.']"
                          label="Número de Pessoas"
                          no-data-text
                          autocomplete="disabled"
                          :name="Math.random()"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 md10 v-if="nroPessoas == 2">
                        <v-text-field
                          :disabled="isUpdating"
                          background-color="white"
                          v-model="parceiro"
                          label="Nome do Parceiro(a)"
                          maxlength="100"
                          :rules="[(value) => valida_nome(value) || 'Nome inválido', (value) => !value || value.length > 1 || 'Preencha o nome do parceiro(a)']"
                          color="blue-grey lighten-2"
                          @change="parceiro = pLetra(parceiro)"
                          autocomplete="disabled"
                          :name="Math.random()"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12></v-flex>

                      <v-flex xs12 md5 v-if="!existeTelefone">
                        <h4 class="overline font-italic">Whatsapp</h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs4>
                            <v-text-field
                              :disabled="isUpdating"
                              v-model="ddd"
                              label="DDD"
                              v-mask="'##'"
                              hint="Obrigatório"
                              :rules="[(value) => !!value || 'Obrigatório.', (value) => isDDDvalido(value)]"
                              color="blue-grey lighten-2"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs8>
                            <v-text-field
                              :disabled="isUpdating"
                              v-model="fone"
                              label="Número"
                              v-mask="'###########'"
                              hint="Obrigatório"
                              :rules="[(value) => !!value || 'Obrigatório.', (value) => (value != null && value.length > 7) || 'Número inválido']"
                              color="blue-grey lighten-2"
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12></v-flex>

                      <v-flex xs12 md5 v-if="!existeEndereco">
                        <h4 class="overline font-italic">Endereço</h4>
                        <v-divider></v-divider>
                        <v-layout wrap>
                          <v-flex xs6>
                            <v-text-field
                              :disabled="isUpdating"
                              v-model="cep"
                              label="CEP"
                              v-mask="'#####-###'"
                              hint="Obrigatório"
                              :rules="[(value) => !!value || 'Obrigatório.', (value) => (value && value.length == 9) || 'CEP Inválido']"
                              color="blue-grey lighten-2"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs6>
                            <v-text-field
                              :disabled="isUpdating"
                              v-model="numero"
                              label="Número"
                              v-mask="'#####'"
                              hint="Obrigatório - Use 0 para S/N"
                              :rules="[(value) => !!value || 'Obrigatório.', (value) => (value >= 0 && value <= 99999) || 'Obrigatório']"
                              color="blue-grey lighten-2"
                              autocomplete="disabled"
                              :name="Math.random()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12>
                        <v-sheet class="ml-1 red--text" :value="true" v-if="mensagemErro">{{ mensagemErro }}</v-sheet>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card>
              <v-btn color="primary" @click="pagamento"> Confirmar </v-btn>
            </v-stepper-content>

            <v-stepper-content step="6">
              <!-- Confirmação da reserva -->
              <v-card class="mb-12">
                <v-card-title>
                  <h3 style="color: blue">Reserva Confirmada!</h3>
                  <v-divider></v-divider>
                </v-card-title>
                <v-alert :value="true" color="success" dense>Cabana: Gracillis</v-alert>
                <v-alert :value="true" color="success" dense>Checkin: {{ formatDate(dtInicio) }} 15hs</v-alert>
                <v-alert :value="true" color="success" dense>Checkout: {{ formatDate(dtFim) }} 12hs</v-alert>
                <v-alert :value="true" color="success" dense>Valor da Reserva: {{ formatCurrency(nroPessoas == 2 ? vlrReserva : vlrReserva * 0.9) }}</v-alert>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '80px' : '60px'};`"></v-flex>

      <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>

      <v-dialog persistent v-model="dialogErro" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">ERRO</span>
          </v-toolbar>
          <v-card-text class="mt-4"
            ><b><span v-html="msgErro"></span></b>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              @click="
                cobranca = '';
                dialogErro = false;
              "
              >{{ cobranca == "ERRO" ? "Fechar" : cobranca == "EXISTE" ? "Prosseguir nesta Reserva" : "Tentar Novamente" }}</v-btn
            >
            <v-btn v-if="cobranca == 'EXISTE'" color="green darken-1" text @click="cancelarReserva">Cancelar Pré-Reserva</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogPagamento" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Pagamento</span>
          </v-toolbar>
          <v-card-text class="mt-4"
            ><b
              ><p>Em instantes, você será direcionado para o ambiente seguro do Asaas para realizar o pagamento.</p>
              <p>Caso isto não ocorra, clique <a :href="payAsaas">AQUI</a> para o redirecionamento.</p></b
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import util from "../util.js";
import Gracillis from "./components/Gracillis.vue";
import Mapa from "./components/Mapa.vue";
import { api } from "@/services/api.js";

export default {
  components: {
    Gracillis,
    Mapa
  },
  props: {
    contato: { default: false }
  },
  data() {
    return {
      etapa: 1,
      idCabana: 1,
      dtSelecionada: null,
      tipo: null,

      login: "",
      senha: "",
      idCliente: null,
      cliente: null,
      token: null,

      tab: 1,
      dtInicio: null,
      dtFim: null,
      vlrReserva: 0,
      nroPessoas: 2,
      identificacao: "",
      nome: "",
      parceiro: "",
      email: "",
      ddd: "",
      fone: "",
      cep: "",
      numero: "",
      formaPgto: "PIX",
      nroParcelas: 1,
      maxParcelas: 1,
      itensParcelas: [1],

      tokenEmail: "",
      tokenValidar: false,

      existeIdentificacao: true,
      existeTelefone: true,
      existeEndereco: true,

      loginValid: true,
      novoValid: true,
      esqueciValid: true,
      adicionalValid: true,
      validacao: { login: false, senha: false },
      logar: false,

      dialogErro: false,
      msgErro: "",
      mensagem: "",

      dialogPagamento: false,
      payAsaas: null,
      cobranca: false,

      snackbar: false,
      snackErro: false,
      isUpdating: false,
      mensagemErro: ""
    };
  },
  computed: {
    reservaON() {
      return false;
    }
  },
  watch: {
    dtSelecionada(val) {
      if (val) {
        if (this.tipo == "checkin") {
          this.dtInicio = val;
          this.etapa++;
        } else {
          this.dtFim = val;
          this.etapa++;
        }
      }
    },
    etapa(val) {
      if (val == 3) {
        this.dtSelecionada = null;
        this.tipo = "checkin";
        if (this.$vuetify.breakpoint.width < 400) {
          this.mensagem = "Vire sua tela para ver corretamente os valores";
          this.snackErro = true;
        }
      } else if (val == 4) {
        this.dtSelecionada = null;
        this.tipo = "checkout";
        if (this.$vuetify.breakpoint.width < 400) {
          this.mensagem = "Vire sua tela para ver corretamente os valores";
          this.snackErro = true;
        }
      } else if (val == 5) {
        this.prereservar();
        // } else if (val == 6) {
        //   this.pagamento();
      }
    },
    formaPgto(val) {
      if (val && val.id == "CREDIT_CARD") {
        this.itensParcelas = [];
        for (let ii = 1; ii <= this.maxParcelas; ii++) {
          this.itensParcelas.push(ii);
        }
      } else {
        this.itensParcelas = [1];
        this.nroParcelas = 1;
      }
    }
  },
  methods: {
    async cadastrar() {
      if (!this.$refs.formNovo.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      //TODO:
      //1. validar os dados e enviar e-mail com o código
      //2. se código ok, inserir os dados básicos do cliente e já fazer seu login e retornar seu token
      this.isUpdating = true;
      try {
        //* Busca o ip local do usuário
        var ip = { ip: "" };
        try {
          const responseIP = await axios.get("https://ipinfo.io/ip");
          ip = { ip: responseIP.data };
        } catch (e) {
          console.log(e);
        }

        const objeto = {
          identificacao: this.identificacao,
          nome: this.nome,
          email: this.email,
          tokenEmail: this.tokenEmail,
          ddd: this.ddd,
          fone: this.fone,
          ip: ip
        };

        // const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "public/dados/" + this.id, objeto);
        // if (e.response.data.tipo == "tokenEmail") {
        //     this.tokenValidar = true;
        // } else
        // this.idCliente = retorno.data.id;

        this.mensagem = "Seu cadastro foi registrado!";
        this.snackbar = true;
        this.etapa = 3;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.length) {
          this.msgErro = "<p style='color: red'>Ocorreu um erro ao realizar seu cadastro!</p><br />" + e.response.data[0].mensagem;
        } else {
          this.msgErro = "";
        }
        this.cobranca = "";
        this.dialogErro = true;
        this.dtSelecionada = null;
        this.tipo = "";
        this.dtInicio = null;
        this.dtFim = null;
        this.etapa = 2;
      } finally {
        this.isUpdating = false;
      }
    },

    async acessar() {
      if (!this.$refs.formLogin.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;

        return;
      }
      this.isUpdating = true;
      await util.limparObjeto(this.validacao, false);
      try {
        var objeto = {
          login: this.login,
          senha: this.senha,
          chave: "3spac0Moss0o"
        };

        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "public/cliente/auth-token", objeto);

        //* Busca o ip local do usuário
        var ip = { ip: "" };
        try {
          const responseIP = await axios.get("https://ipinfo.io/ip");
          ip = { ip: responseIP.data };
        } catch {}

        objeto = {
          client_id: "mossoo",
          client_secret: "3spac0Moss0o",
          code: response.data.token,
          ip: ip.ip
        };

        const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "public/cliente/access-token", objeto);

        this.idCliente = retorno.data.usuario.id;
        this.cliente = retorno.data.usuario.nome;
        this.existeIdentificacao = retorno.data.usuario.identificacao && retorno.data.usuario.identificacao.length;
        this.existeTelefone = retorno.data.usuario.ddd && retorno.data.usuario.fone;
        this.existeEndereco = retorno.data.usuario.cep && retorno.data.usuario.numero;

        this.token = retorno.data.token;

        if (retorno.data.reserva && retorno.data.reserva.id) {
          this.id = retorno.data.reserva.id;
          this.dtInicio = retorno.data.reserva.dtInicio;
          var dtFim = util.parseDateUS(retorno.data.reserva.dtFim);
          dtFim.setDate(dtFim.getDate() + 1);
          this.dtFim = util.dateToStringUS(dtFim);
          this.vlrReserva = retorno.data.reserva.vlrReserva;

          this.maxParcelas = parseInt(this.vlrReserva / 200);
          if (this.maxParcelas < 1) {
            this.maxParcelas = 1;
          }
          if (this.maxParcelas > 12) {
            this.maxParcelas = 12;
          }

          if (this.formaPgto.id == "CREDIT_CARD") {
            this.itensParcelas = [];
            for (let ii = 1; ii <= this.maxParcelas; ii++) {
              this.itensParcelas.push(ii);
            }
          } else {
            this.itensParcelas = [1];
            this.nroParcelas = 1;
          }
          this.etapa = 5;

          this.cobranca = "EXISTE";
          this.msgErro =
            "Você já tem uma pré-reserva registrada em outro período.<p>Clique em PROSSEGUIR NESTA RESERVA, para confirmar seus dados e realizar o pagamento</p><p>OU</p><p>Clique em CANCELAR PRÉ-RESERVA para liberar o período e tentar novamente.</p>";
        } else {
          this.etapa = 3;
        }
      } catch (e) {
        console.log(e);
        util.validaErros(this.validacao, e.response.data);
      } finally {
        this.isUpdating = false;
      }
    },

    async esqueci() {
      try {
        await util.limparObjeto(this.validacao, false);

        if (!this.$refs.formEsqueci.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        this.isUpdating = true;

        //* Define os dados a serem enviados
        var body = {
          identificacao: this.identificacao
        };

        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "public/cliente/recuperar-senha", body);

        //* mostra email parcial, senão qualquer um com um cpf obteria o e-mail do cliente
        var partes = retorno.data.email.split("@");
        var emailRet = partes[0].substr(0, 2) + "***@";
        emailRet += partes[1];
        this.mensagem = "Uma nova senha foi enviada para o e-mail " + emailRet;
        this.snackbar = true;
        this.logar = true;
      } catch (e) {
        util.validaErros(this.validacao, e.response.data);
      } finally {
        this.isUpdating = false;
      }
    },

    async prereservar() {
      if (this.cobranca == "EXISTE") {
        return;
      }

      this.isUpdating = true;
      try {
        const objeto = {
          idCabana: this.idCabana,
          idCliente: this.idCliente,
          dtInicio: this.dtInicio,
          dtFim: this.dtFim
        };

        const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "public/reserva", objeto);
        this.id = retorno.data.id;
        this.vlrReserva = retorno.data.vlrReserva;
        this.maxParcelas = parseInt(this.vlrReserva / 200);
        if (this.maxParcelas < 1) {
          this.maxParcelas = 1;
        }
        if (this.maxParcelas > 12) {
          this.maxParcelas = 12;
        }

        if (this.formaPgto.id == "CREDIT_CARD") {
          this.itensParcelas = [];
          for (let ii = 1; ii <= this.maxParcelas; ii++) {
            this.itensParcelas.push(ii);
          }
        } else {
          this.itensParcelas = [1];
          this.nroParcelas = 1;
        }

        this.mensagem = "Sua pré-reserva foi registrada!";
        this.snackbar = true;
      } catch (e) {
        if (e.response.data.prereserva) {
          this.id = e.response.data.id;
          this.dtInicio = e.response.data.dtInicio;
          this.dtFim = e.response.data.dtFim;
          this.vlrReserva = e.response.data.vlrReserva;

          this.maxParcelas = parseInt(this.vlrReserva / 200);
          if (this.maxParcelas < 1) {
            this.maxParcelas = 1;
          }
          if (this.maxParcelas > 12) {
            this.maxParcelas = 12;
          }

          if (this.formaPgto.id == "CREDIT_CARD") {
            this.itensParcelas = [];
            for (let ii = 1; ii <= this.maxParcelas; ii++) {
              this.itensParcelas.push(ii);
            }
          } else {
            this.itensParcelas = [1];
            this.nroParcelas = 1;
          }

          this.cobranca = "EXISTE";
          this.msgErro =
            "Você já tem uma pré-reserva registrada em outro período.<p>Clique em PROSSEGUIR NESTA RESERVA, para confirmar seus dados e realizar o pagamento</p><p>OU</p><p>Clique em CANCELAR PRÉ-RESERVA para liberar o período e tentar novamente.</p>";
        } else {
          this.dtSelecionada = null;
          this.tipo = "";
          this.dtInicio = null;
          this.dtFim = null;
          this.etapa = 3;

          this.cobranca = "NOVAMENTE";
          if (e.response && e.response.data && e.response.data.length) {
            this.msgErro = "<p style='color: red'>Ocorreu um erro ao registrar sua pré-reserva!</p><br />" + e.response.data[0].mensagem;
          } else {
            this.msgErro = "";
          }
        }

        this.dialogErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    async pagamento() {
      if (!this.$refs.formAdicional.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      //* Envia dados adicionais, registra a cobrança no Asaas e envia e-mail da pré-reserva

      //Criar cobrança Asaas, pegar o pay_ e direcionar para a tela da cobrança
      //enviar e-mail com dados da reserva e status=Aguardando Pagamento
      //ver se Asaas redireciona para o site para cliente não ficar boiando
      //se redirecionar, validar a cobrança e apresentar tela de Reserva Confirmada
      //ao receber confirmação, enviar email de confirmação com link para logar e cancelar (ver política)
      //2? dias antes dar acesso a tela de kits, instruções da cabana (personalizada com o status da banheira, etc)

      this.isUpdating = true;
      try {
        var objeto = {
          idCliente: this.idCliente,
          nroPessoas: this.nroPessoas,
          parceiro: this.parceiro,
          formaPgto: this.formaPgto.id,
          nroParcelas: this.nroParcelas
        };
        if (this.identificacao) {
          objeto.identificacao = this.identificacao;
        }
        if (this.ddd) {
          objeto.ddd = this.ddd;
          objeto.fone = this.fone;
        }
        if (this.cep) {
          objeto.cep = util.somenteNumeros(this.cep);
          objeto.numero = this.numero;
        }

        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "public/reserva/" + this.id, objeto);
        const statusPagamento = retorno.data;
        if (statusPagamento.status == "PENDING") {
          this.payAsaas = process.env.VUE_APP_URL_ASAAS + "/i/" + statusPagamento.payAsaas.replace("pay_", "");
          this.dialogPagamento = true;
          setTimeout(() => {
            window.location = this.payAsaas;
          }, 5000);
        } else if (["CONFIRMED", "RECEIVED"].includes(statusPagamento.status)) {
          this.etapa = 6;
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.length) {
          this.msgErro =
            "<p style='color: red'>Ocorreu um erro ao registrar sua cobrança!</p><br />" +
            e.response.data[0].mensagem +
            "<br /><br />Favor entrar em contato conosco para resolvermos o problema.";
        } else {
          this.msgErro = "";
        }
        this.cobranca = "ERRO";
        this.dialogErro = true;
        this.etapa = 6;
      } finally {
        this.isUpdating = false;
      }
    },

    async cancelarReserva() {
      this.isUpdating = true;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "public/reserva/cancelar/" + this.id);
        this.mensagem = "Sua pré-reserva foi cancelada!";
        this.snackbar = true;
        this.etapa = 1;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.length) {
          this.msgErro =
            "<p style='color: red'>Ocorreu um erro ao cancelar sua pré-reserva!</p><br />" +
            e.response.data[0].mensagem +
            "<br /><br />Favor entrar em contato conosco para resolvermos o problema.";
        } else {
          this.msgErro = "";
        }
        this.cobranca = "ERRO";
        this.dialogErro = true;
        this.etapa = 6;
      } finally {
        this.isUpdating = false;
      }
    },

    async funcContato() {
      this.$emit("update:contato", true);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_identificacao(text) {
      if (text != util.somenteNumeros(text) || (!util.valida_cpf(text) && !util.valida_cnpj(text))) {
        return "Identificação inválida";
      }
      return true;
    },
    valida_email(email) {
      if (email && !util.valida_email(email)) {
        return "E-mail inválido.";
      } else {
        return true;
      }
    },
    valida_email_identificacao(text) {
      if (text) {
        if (text == util.somenteNumeros(text)) {
          if (!util.valida_cpf(text) && !util.valida_cnpj(text)) {
            return "Identificação inválida";
          }
        } else {
          if (!util.valida_email(text)) {
            return "E-mail inválido.";
          }
        }
      }
      return true;
    },
    isDDDvalido(ddd) {
      if (!util.isDDDvalido(ddd)) {
        return "DDD inválido";
      }
      return true;
    }
  },
  async created() {
    if (this.$route.query.reserva && parseInt(this.$route.query.reserva) > 0) {
      //* Se Asaas direcionou com pagamento confirmado, mostra os dados da reserva confirmada
      // try {
      //   const response = await api.get(process.env.VUE_APP_URL_WEB_API + "public/reserva/" + this.$route.query.reserva);
      //   this.reserva = response.data
      this.etapa = 6;
      // } catch {}
    }
  }
};
</script>
