<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Sobre Nós</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.sobre1"></p>
      </v-flex>
      <v-flex xs12 align-center>
        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6>
            <v-carousel hide-delimiters cycle :height="$vuetify.breakpoint.xs ? '200' : '400'">
              <v-carousel-item v-for="(item, i) in itens1" :key="i" :src="item.src"></v-carousel-item>
            </v-carousel>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>

      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.sobre2"></p>
      </v-flex>
      <v-flex xs12 align-center>
        <v-layout wrap>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6>
            <v-carousel hide-delimiters :height="$vuetify.breakpoint.xs ? '200' : '400'">
              <v-carousel-item v-for="(item, i) in itens2" :key="i" :src="item.src"></v-carousel-item>
            </v-carousel>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
      </v-flex>

      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.sobre3"></p>
      </v-flex>
      <v-flex xs12 align-center>
        <v-img class="img-responsive" src="sobre/vista.jpg" :height="$vuetify.breakpoint.xs ? 150 : 300"> </v-img>
      </v-flex>

      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.xs ? 'p12' : 'p13'" v-html="$store.state.sobre4"></p>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '80px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      itens1: [
        { src: "sobre/construcao.jpg" },
        { src: "sobre/tijolinhos.png" },
        { src: "sobre/pedra.jpg" },
        { src: "sobre/cordwood.jpg" },
        { src: "sobre/madeira.jpg" },
        { src: "sobre/madeira2.jpg" }
      ],
      itens2: [{ src: "sobre/pessegos.jpg" }, { src: "sobre/permacultura2.jpg" }, { src: "sobre/permacultura1.jpg" }, { src: "sobre/kiwi.jpg" }, { src: "sobre/bananas.jpg" }]
    };
  }
};
</script>
