import { isDate } from "util";

var util = {
  calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
    digitos = digitos.toString();
    for (var i = 0; i < digitos.length; i++) {
      soma_digitos = soma_digitos + digitos[i] * posicoes;
      posicoes--;
      if (posicoes < 2) {
        posicoes = 9;
      }
    }
    soma_digitos = soma_digitos % 11;
    if (soma_digitos < 2) {
      soma_digitos = 0;
    } else {
      soma_digitos = 11 - soma_digitos;
    }

    var cpf = digitos + soma_digitos;

    return cpf;
  },
  valida_cpf(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, "");
    var digitos = valor.substr(0, 9);
    var novo_cpf0 = this.calc_digitos_posicoes(digitos);
    var novo_cpf = this.calc_digitos_posicoes(novo_cpf0, 11);
    if (
      novo_cpf === valor &&
      novo_cpf !== "00000000000" &&
      novo_cpf !== "11111111111" &&
      novo_cpf !== "22222222222" &&
      novo_cpf !== "33333333333" &&
      novo_cpf !== "44444444444" &&
      novo_cpf !== "55555555555" &&
      novo_cpf !== "66666666666" &&
      novo_cpf !== "77777777777" &&
      novo_cpf !== "88888888888" &&
      novo_cpf !== "99999999999"
    ) {
      return true;
    } else {
      return false;
    }
  },
  valida_cnpj(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, "");
    if (parseInt(valor) == 0) {
      return false;
    }
    var cnpj_original = valor;
    var primeiros_numeros_cnpj = valor.substr(0, 12);
    var primeiro_calculo = this.calc_digitos_posicoes(primeiros_numeros_cnpj, 5);
    var segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);
    var cnpj = segundo_calculo;
    if (cnpj === cnpj_original) {
      return true;
    }
    return false;
  },

  valida_nome(text) {
    if (text == "") {
      //* Não obriga o preenchimento, apenas verifica se não possui caracteres estranhos
      return true;
    }
    const textRegexp = /^[A-Za-záàâãéèêíïóôõöúüçñÁÀÂÃÉÈÍÏÓÔÕÖÚÜÇÑ'ºª&/.\- \d]+$/;
    return textRegexp.test(text);
  },
  valida_email(email) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegexp.test(email);
  },
  valida_data_BR(date) {
    var retorno = true;
    if (date) {
      date = date.substr(0, 10); //* remove a parte do horário
      //limite para datas: 01/01/2010 a hoje+20 anos
      var dataAtualAux = new Date();
      var dataLimiteInicio = new Date(new Date(2010, 0, 1));
      var dataLimiteFim = new Date(new Date(dataAtualAux.getFullYear() + 20, dataAtualAux.getMonth(), 1));

      var x = date.split("/");
      var confere = new Date(x[2], x[1] - 1, x[0]);
      var confere2 =
        (confere.getDate() < 10 ? "0" : "") + confere.getDate() + "/" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "/" + confere.getFullYear();
      if (!confere || date != confere2 || confere < dataLimiteInicio || confere > dataLimiteFim) {
        retorno = false;
      }
    } else {
      retorno = false;
    }
    return retorno;
  },
  valida_data(date) {
    var retorno = true;
    if (date != null && date != "") {
      date = date.substr(0, 10); //* remove a parte do horário
      var dataAtualAux = new Date();
      var dataLimiteInicio = new Date(new Date(2010, 0, 1));
      var dataLimiteFim = new Date(new Date(dataAtualAux.getFullYear() + 20, dataAtualAux.getMonth(), 1));

      var x = date.split("-");
      var confere = new Date(x[0], x[1] - 1, x[2]);
      var confere2 =
        confere.getFullYear() + "-" + (confere.getMonth() + 1 < 10 ? "0" : "") + (confere.getMonth() + 1) + "-" + (confere.getDate() < 10 ? "0" : "") + confere.getDate();
      if (!confere || date != confere2 || confere < dataLimiteInicio || confere > dataLimiteFim) {
        retorno = false;
      }
    } else {
      retorno = false;
    }
    return retorno;
  },
  isDDDvalido(ddd) {
    var retorno = ddd.match("((([1,4,6,8,9][1-9])|(2[1,2,4,7,8])|(3[1-5])|(3[7-8])|(5[1,3,4,5])|(7[1,3,4,5,7,9])))");
    return retorno != null;
  },

  pLetra(text) {
    if (!text || text === "" || text.trim() === "") {
      return "";
    }

    //* Remove excesso de espaços em branco
    text = text.trim();
    do {
      text = text.replace("  ", " ");
    } while (text.indexOf("  ") > 0);

    if (text !== text.toLowerCase() && text !== text.toUpperCase()) {
      //* Só irá converter se todos os caracteres estiverem em minúsculos ou maiúsculos (para permitir manter uma correção, ex: APM - Associação de Pais e Mestres)
      return text;
    }
    if (text === text.toUpperCase()) {
      //* Para padronização do sistema, não permite nomes totalmente em maiúsculas
      text = text.toLowerCase();
    }
    var words = text.split(" ");
    for (var a = 0; a < words.length; a++) {
      //* Converte primeira letra de cada palavra em maiúsculo, ignorando os artigos e conjunções abaixo
      var w = words[a];
      words[a] = w[0].toUpperCase() + w.slice(1).toLowerCase();
      if (w === "da" || w === "de" || w === "do" || w === "das" || w === "dos" || w === "a" || w === "e" || w === "o" || w === "com" || w === "na" || w === "em" || w === "no") {
        words[a] = w;
      }
      if (w === w.toUpperCase()) {
        //* Mantém siglas em maiúsculas
        words[a] = w;
      }
    }
    return words.join(" ");
  },
  validaErros(objeto, erros) {
    //faz um map nas mensagens de retorno
    erros.map((erro) => {
      //faz um map no objeto de validação
      Object.keys(objeto).map((campo) => {
        //se encontrar o campo na mensagem ele seta a mensagem de erro
        if (erro.campo == campo) {
          if (Array.isArray(erro.mensagem)) {
            objeto[campo] = "";
            erro.mensagem.map((element) => {
              //se encontrar o campo na mensagem ele seta a mensagem de erro
              objeto[campo] += (objeto[campo] == "" ? "" : " | ") + element.mensagem;
            });
          }
          if (!objeto[campo]) {
            objeto[campo] = erro.mensagem;
          }
        }
      });
    });
  },

  limparObjeto(objeto, valor) {
    Object.keys(objeto).map((campo) => {
      objeto[campo] = valor;
    });
  },
  somenteNumeros(valor) {
    return valor.replace(/[^0-9]/g, "");
  },
  removeAcentos(texto) {
    if (texto) {
      texto = texto.replace(/\s\s+/g, " ");
      texto = texto.replace(/[°]/g, "o");
      texto = texto.replace(/[º]/g, "o");
      texto = texto.replace(/[ª]/g, "a");
      texto = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      texto = texto.trim();
    }
    return texto;
  },

  filtrarObjetos(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = util.removeAcentos(item["nome"]).toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  filtrarItens(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = util.removeAcentos(item).toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },
  filtrarUFs(item, queryText, itemText) {
    if (queryText === null || queryText.length === 0) {
      return true;
    }
    var text = (queryText.length > 2 ? util.removeAcentos(item["nome"]).toLowerCase() : "") + "-" + item["sigla"].toLowerCase() + "-" + item["pais"].toLowerCase();
    queryText = util.removeAcentos(queryText).toLowerCase();
    return itemText && itemText.length > 0 && text.indexOf(queryText) > -1;
  },

  formatCurrency(valor, mostrarSimbolo) {
    if (!valor) {
      valor = 0;
    }
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    var retorno = formatter.format(valor);
    if (mostrarSimbolo) {
      retorno = "R$ " + retorno;
    }
    return retorno;
  },
  formatDate(date) {
    try {
      if (date && isDate(date)) {
        date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
      if (!date) return null;
      if (date.indexOf("T")) {
        date = date.split("T")[0];
      }
      if (date.indexOf(" ")) {
        date = date.split(" ")[0];
      }
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    } catch {
      return null;
    }
  },
  formatDateTime(datetime) {
    if (!datetime) return null;
    if (datetime && datetime != null && datetime != "") {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      datetime = new Date(datetime);
      var portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
      return portugueseTime.format(datetime);
    } else {
      return null;
    }
  },
  formatDateUS(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  },
  formatDDMM(date) {
    try {
      if (date && isDate(date)) {
        date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
      if (!date) return null;
      if (date.indexOf("T")) {
        date = date.split("T")[0];
      }
      if (date.indexOf(" ")) {
        date = date.split(" ")[0];
      }
      const partes = date.split("-");
      return `${partes[2].padStart(2, "0")}/${partes[1].padStart(2, "0")}`;
    } catch {
      return null;
    }
  },

  parseDate(date) {
    if (!date || date.length < 10) return null;
    date = date.substr(0, 10); //* remove a parte do horário
    const [day, month, year] = date.split("/");
    return new Date(year, month - 1, day);
  },
  parseDateUS(date) {
    if (!date) return null;
    if (isDate(date)) {
      date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate();
    }
    date = date.substr(0, 10); //* remove a parte do horário
    const [year, month, day] = date.split("-");
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  },

  dateToStringUS(date) {
    if (isDate(date)) {
      date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
    if (!date) return null;
    if (date.indexOf("T")) {
      date = date.split("T")[0];
    }
    if (date.indexOf(" ")) {
      date = date.split(" ")[0];
    }
    const [year, month, day] = date.split("-");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
};

export default util;
