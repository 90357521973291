<template>
  <v-footer inset color="#752921" class="white--text justify-center" app>
    <span class="p12">
      <strong>
        <a target="_blank" class="estilo-link-site" href="https://espacomossoo.com.br">Espaço Mossoo © </a>
      </strong>
      - Alfredo Wagner - Santa Catarina - Brasil
    </span>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      options: { duration: 1000, offset: 0, easing: "easeInOutCubic" }
    };
  }
};
</script>
<style></style>
