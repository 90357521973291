<template>
  <div id="inspire">
    <div>
      <v-layout class="justify-center" justify-center>
        <v-card outlined color="rgba(0, 244, 255, 0.7)" class="elevation-12 pt-6 card-login">
          <v-card-text>
            <h1 style="color: white" class="display-1 font-weight-thin mb-2">
              Espaço Mossoo <span style="font-size: 16px">{{ $store.state.versao }}</span>
            </h1>
            <v-form ref="formLogin" v-model="loginValid" lazy-validation>
              <v-text-field
                background-color="white"
                v-model="usuario.email"
                prepend-icon="person"
                maxlength="35"
                label="Login"
                filled
                hint="Obrigatório"
                :rules="[validacao.usuario == false || validacao.usuario, (value) => !!value || 'Obrigatório.']"
              ></v-text-field>
              <v-text-field
                background-color="white"
                v-model="usuario.senha"
                prepend-icon="lock"
                label="Senha"
                maxlength="35"
                filled
                type="password"
                hint="Obrigatório"
                :rules="[validacao.senha == false || validacao.senha, (value) => !!value || 'Obrigatório.']"
              ></v-text-field>

              <v-sheet v-if="mensagemErro" class="ml-1 red--text" :value="true">{{ mensagemErro }}</v-sheet>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn class="btn-login" color="white lighten-2" block @click="logar">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </div>
  </div>
</template>
<style scoped>
.card-login {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 400px;
}

@media screen and (max-width: 600px) {
  .card-login {
    width: 290px;
  }
  .btn-login {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
<script>
import util from "../util";
import VueCookies from "vue-cookies";
export default {
  data() {
    return {
      usuario: {
        email: "",
        senha: ""
      },
      validacao: {
        usuario: false,
        senha: false
      },
      loginValid: true,
      mensagemErro: ""
    };
  },

  methods: {
    async logar() {
      var senha = this.usuario.senha;
      try {
        await util.limparObjeto(this.validacao, false);
        await this.$store.dispatch("logarUsuario", this.usuario); //loga o usuario
        await this.$store.dispatch("getUsuario", this.usuario.email); //se deu certo pega informações do usuário

        try {
          if (this.$route.query && this.$route.query.redirect) {
            if (this.$route.query.redirect.startsWith("/")) {
              //* Retorna para a mesma página onde estava antes de fazer o logoff (se não tiver acesso irá para a página acesso negado)
              await this.$router.push(this.$route.query.redirect);
            } else {
              //* Envia para página externa
              window.location.href = this.$route.query.redirect;
            }
          } else {
            await this.$router.push("/administrativo");
          }
        } catch {
          await this.$router.push("/administrativo");
        }
      } catch (e) {
        this.usuario.senha = senha;
        //valida se tem mensagem de retorno
        if (e.response && e.response.data) {
          //função realiza todas as validações de erro
          util.validaErros(this.validacao, e.response.data);
        }
        //realiza o dispatch para as mensagens
        this.$refs.formLogin.validate();
      }
    }
  }
  // beforeMount() {
  //   if (VueCookies.get("erros") == "true") {
  //     this.validacao.usuario = "Usuário não possui acesso ao sistema";
  //   }
  //   VueCookies.set("erros", false);
  // }
};
</script>
