<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'">Instruções do Ar Condicionado Quente/Frio</p>
      </v-flex>
      <v-flex xs12 align-center style="padding: 0px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">Para sua comodidade, a Cabana Gracillis possui sistema de ar condicionado quente e frio.</p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          A maneira mais rápida de configurar é selecionar o modo automático
          <v-img style="display: inline-block" class="img-responsive" src="hospedagem/ar-auto.png" :width="$vuetify.breakpoint.xs ? 28 : 35"> </v-img>, através da tecla [Modo]
          <v-img style="display: inline-block" class="img-responsive" src="hospedagem/ar-modo.png" :width="$vuetify.breakpoint.xs ? 134 : 167"> </v-img>.
        </p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Em seguida, selecione a temperatura desejada, utilizando os botões [-]/[+]:
          <v-img style="display: inline-block" class="img-responsive" src="hospedagem/ar-temperatura.png" :width="$vuetify.breakpoint.xs ? 211 : 264"> </v-img>.
        </p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Caso deseje alterar a direção do ar, ou deixar oscilando, utilize uma das teclas:
          <v-img style="display: inline-block" class="img-responsive" src="hospedagem/ar-oscilar.png" :width="$vuetify.breakpoint.xs ? 78 : 97"></v-img>.
        </p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          <u>Dicas</u>:<br />
          - Use o modo silencioso (não perturbe), que irá inclusive desligar a luzinha:
          <v-img style="display: inline-block" class="img-responsive" src="hospedagem/ar-silencioso.png" :width="$vuetify.breakpoint.xs ? 95 : 119"></v-img>;<br />
          - No inverno (abaixo de 13ºC), deixe o ar quente ligado e o quarto aquecido ao utilizar a banheira.
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
