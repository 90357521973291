<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px;" v-if="$vuetify.breakpoint.xs">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Blog</p>
      </v-flex>

      <v-flex xs12 align-center>
        <v-img
          class="img-responsive"
          src="blog/por-do-sol.jpg"
          :height="
            $vuetify.breakpoint.xs ? 150 : $vuetify.breakpoint.sm ? 200 : 600
          "
        >
        </v-img>
      </v-flex>

      <v-flex
        v-if="$vuetify.breakpoint.smAndDown"
        xs12
        class="text-center"
        style="margin-top: 40px; margin-bottom: 40px;"
      >
        <p
          :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
          v-html="$store.state.blog"
        ></p>
      </v-flex>
      <v-flex
        v-if="$vuetify.breakpoint.mdAndUp"
        offset-md3
        md6
        class="text-center"
        style="margin-top: 40px; margin-bottom: 40px;"
      >
        <p
          :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
          v-html="$store.state.blog"
        ></p>
      </v-flex>

      <v-flex xs12 align-center>
        <v-img
          class="img-responsive"
          src="blog/bambus.jpg"
          :height="
            $vuetify.breakpoint.xs ? 150 : $vuetify.breakpoint.sm ? 200 : 600
          "
        >
        </v-img>
      </v-flex>

      <v-flex xs12 align-center>
        <v-divider style="margin: 20px;"></v-divider>
      </v-flex>

      <v-expansion-panels v-model="painel" popout focusable>
        <v-expansion-panel>
          <v-expansion-panel-header color="#99b8bf">
            Bambu - Bamboo
          </v-expansion-panel-header>
          <v-expansion-panel-content id="bambu">
            <v-layout wrap>
              <!-- BAMBU - BAMBOO -->
              <v-flex xs9>
                <p :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">
                  Bambu - Bamboo
                </p>
              </v-flex>
              <v-flex xs3 class="text-right" v-if="$vuetify.breakpoint.smAndUp">
                <v-img
                  style="float: right; margin-right: 20px; opacity: 0.5;"
                  class="img-responsive"
                  src="blog/bambu/folha.png"
                  max-width="120"
                ></v-img>
              </v-flex>

              <v-flex
                xs12
                style="margin-top: -60px;"
                v-if="$vuetify.breakpoint.smAndUp"
              >
                <v-layout wrap>
                  <v-flex xs6>
                    <v-img
                      class="img-responsive"
                      src="blog/bambu/bambus-ceu.jpg"
                      :height="$vuetify.breakpoint.sm ? 760 : 600"
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs6>
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                      v-html="$store.state.bambu_bamboo1"
                    ></p>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                xs12
                style="margin-top: -20px;"
                v-if="$vuetify.breakpoint.xs"
              >
                <v-img
                  class="img-responsive"
                  src="blog/bambu/bambus-ceu.jpg"
                  height="250"
                >
                </v-img>
              </v-flex>
              <v-flex xs12 v-if="$vuetify.breakpoint.xs">
                <v-img
                  style="right: 0px; opacity: 0.5; position: absolute; z-index: 1;"
                  class="img-responsive"
                  src="blog/bambu/folha.png"
                  max-width="80"
                ></v-img>
                <p class="p16" v-html="$store.state.bambu_bamboo1"></p>
              </v-flex>

              <v-flex xs12>
                <p
                  :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                  v-html="$store.state.bambu_bamboo2"
                ></p>
              </v-flex>
              <v-flex offset-xs1 xs10 offset-md2 md8 style="margin-top: 20px;">
                <p
                  :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                  v-html="$store.state.bambu_bamboo3"
                ></p>
              </v-flex>

              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs6 sm3>
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                      style="margin-bottom: -4px;"
                    >
                      Mosso
                    </p>
                    <v-img
                      class="img-responsive"
                      src="blog/bambu/mosso.jpg"
                      :height="
                        $vuetify.breakpoint.xs
                          ? 220
                          : $vuetify.breakpoint.sm
                          ? 250
                          : 500
                      "
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs6 sm3>
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                      style="margin-bottom: -4px;"
                    >
                      Gracillis
                    </p>
                    <v-img
                      class="img-responsive"
                      src="blog/bambu/gracillis.jpg"
                      :height="
                        $vuetify.breakpoint.xs
                          ? 220
                          : $vuetify.breakpoint.sm
                          ? 250
                          : 500
                      "
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs6 sm3>
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                      style="margin-bottom: -4px;"
                    >
                      Hatiku
                    </p>
                    <v-img
                      class="img-responsive"
                      src="blog/bambu/hatiku.png"
                      :height="
                        $vuetify.breakpoint.xs
                          ? 220
                          : $vuetify.breakpoint.sm
                          ? 250
                          : 500
                      "
                    >
                    </v-img>
                  </v-flex>
                  <v-flex xs6 sm3>
                    <p
                      :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                      style="margin-bottom: -4px;"
                    >
                      Vitatta
                    </p>
                    <v-img
                      class="img-responsive"
                      src="blog/bambu/vitatta.png"
                      :height="
                        $vuetify.breakpoint.xs
                          ? 220
                          : $vuetify.breakpoint.sm
                          ? 250
                          : 500
                      "
                    >
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12>
                <p :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">
                  #sustentabilidade #bambus #alfredo wagner #serra catarinense
                  #espaco mossoo #caminhos da neve #hospedagem serra catarinense
                </p>
              </v-flex>
            </v-layout>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header color="#99b8bf">
            Araucária
          </v-expansion-panel-header>
          <v-expansion-panel-content id="araucaria">
            <v-container grid-list-md>
              <v-layout wrap>
                <!-- ARAUCÁRIA -->

                <v-flex xs12>
                  <p :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">
                    Araucária
                  </p>
                </v-flex>

                <v-flex xs12 sm6>
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                    v-html="$store.state.araucaria1"
                  ></p>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-img
                    class="img-responsive"
                    src="blog/araucaria/araucaria.jpg"
                    :height="$vuetify.breakpoint.sm ? 940 : 800"
                  >
                  </v-img>
                </v-flex>

                <v-flex xs12>
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                    v-html="$store.state.araucaria2"
                  ></p>
                </v-flex>

                <v-flex xs12 sm6>
                  <v-img
                    class="img-responsive"
                    src="blog/araucaria/araucaria-muda.jpg"
                    :height="$vuetify.breakpoint.smAndDown ? 220 : 400"
                  >
                  </v-img>
                </v-flex>
                <v-flex xs12 sm6 style="margin: auto;" height="400">
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                    v-html="$store.state.araucaria2_1"
                  ></p>
                </v-flex>

                <v-flex xs12 sm6>
                  <v-img
                    class="img-responsive"
                    src="blog/araucaria/araucaria-precoce.jpg"
                    :height="$vuetify.breakpoint.smAndDown ? 220 : 400"
                  >
                  </v-img>
                </v-flex>
                <v-flex xs12 sm6 style="margin: auto;" height="400">
                  <p
                    :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'"
                    v-html="$store.state.araucaria2_2"
                  ></p>
                </v-flex>

                <v-flex xs12>
                  <p :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">
                    #festa do pinhão #alfredo wagner #sustentabilidade
                    #compensação de carbono #serra catarinense #hospedagem serra
                    catarinense #espaço mossoo #cabana gracillis
                  </p>
                </v-flex>
              </v-layout>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-flex
        xs12
        :style="`height: ${$vuetify.breakpoint.xs ? '160px' : '120px'};`"
      ></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return { painel: [] };
  },
  created() {
    if (this.$route.query.blog == "bambu") {
      this.painel = 0;
      window.location.href = "#bambu";
    }
    if (this.$route.query.blog == "araucaria") {
      this.painel = 1;
      window.location.href = "#araucaria";
    }
  }
};
</script>
