<template>
  <v-card class="hide-overflow fill-height">
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">shopping_cart</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Reservas</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="px-6 mt-4">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            background-color="white"
            class="mt-2 mb-2 elevation-0"
            v-model="filtroPesquisa"
            append-icon="search"
            label="Digite para pesquisar..."
            placeholder="Pesquisa pelo nome do cliente, parceiro(a), email, telefone, município, UF, cabana ou origem"
            filled
            hide-details
            @keyup.enter="limpaFiltro"
            @click:append="limpaFiltro"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="registros"
        :hide-default-footer="false"
        :options.sync="options"
        :no-data-text="mensagemPesquisa"
        :footer-props="rodape"
        :disable-sort="carregando"
        :disable-pagination="carregando"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon color="orange darken-1" title="Editar Observação" @click="editaObs(item)">edit</v-icon>
          <v-icon v-if="item.situacao" color="red" title="Cancelar Reserva" @click="cancela(item)">cancel</v-icon>
        </template>
        <template v-slot:item.situacao="{ item }">
          <span :class="item.situacao == 1 ? 'blue--text' : 'red--text'" v-html="item.situacao == 1 ? 'Ativa' : 'Cancelada'"></span>
        </template>
        <template v-slot:item.municipio="{ item }">
          {{ item.municipio ? `${item.municipio}/${item.uf}` : "" }}
        </template>
        <template v-slot:item.dtInicio="{ item }"> {{ formatDate(item.dtInicio) }} 15hs </template>
        <template v-slot:item.dtFim="{ item }"> {{ formatDate(item.dtFim) }} 12hs </template>

        <template v-slot:item.vlrVenda="{ item }">
          {{ formatCurrency(item.vlrVenda) }}
        </template>
        <template v-slot:item.vlrComissao="{ item }">
          {{ formatCurrency(item.vlrComissao) }}
        </template>
        <template v-slot:item.vlrLiquido="{ item }">
          {{ formatCurrency(item.vlrLiquido) }}
        </template>
        <template v-slot:item.vlrCustoFixo="{ item }">
          {{ formatCurrency(item.vlrCustoFixo) }}
        </template>
        <template v-slot:item.vlrLucro="{ item }">
          {{ formatCurrency(item.vlrLucro) }}
        </template>
        <template v-slot:item.vlrPago="{ item }">
          {{ formatCurrency(item.vlrPago) }}
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2 marg-bt">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </div>
    </div>

    <v-dialog persistent v-model="dialogObs" max-width="600px" @keydown.esc="dialogObs = false">
      <v-card>
        <v-form ref="formToken" v-model="obsValid" lazy-validation>
          <v-toolbar>
            <span class="p20">Observação</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogObs = false">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-textarea
                    v-model="itemSelecionado.obs"
                    label="Texto da Observação"
                    maxlength="1000"
                    counter="1000"
                    rows="10"
                    :disabled="isUpdating"
                    :rules="[validacao.obs == false || validacao.obs]"
                    color="blue-grey lighten-2"
                    @change="validacao.obs = false"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!itemSelecionado.situacao" :loading="isUpdating" color="green darken-1" @click="saveObs">Atualizar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogConfirm" max-width="400">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <v-card-text class="mt-4"
          ><p style="color: red"><b>Tem certeza que deseja Cancelar esta Reserva?</b></p>
          <p><b>(todas as suas diárias e kits serão excluídos)</b></p>

          <v-alert v-if="validacao.mensagemErro" :value="true" color="error" icon="block" outlined dense>{{ validacao.mensagemErro }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialogConfirm = false">Não</v-btn>
          <v-btn color="green darken-1" text @click="cancelar">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Situação", value: "situacao" },
        { text: "Tipo", value: "tipo" },
        { text: "Cabana", value: "cabana" },
        { text: "Origem", value: "origem" },
        { text: "Início", value: "dtInicio" },
        { text: "Fim", value: "dtFim" },
        { text: "Cliente", value: "cliente" },
        { text: "Parceiro", value: "parceiro" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "Município", value: "municipio" },
        { text: "Venda", value: "vlrVenda" },
        { text: "Líquido", value: "vlrLiquido" },
        { text: "Lucro", value: "vlrLucro" },
        { text: "Pago", value: "vlrPago" },
        { text: "Avaliação", value: "avaliacao" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      dialogObs: false,
      obsValid: true,
      itemSelecionado: {},
      validacao: { obs: false, mensagemErro: false },
      isUpdating: false,

      dialogConfirm: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/reserva?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.registros.forEach((element) => {
          if (element.dtFim) {
            var dtFim = util.parseDateUS(element.dtFim);
            dtFim.setDate(dtFim.getDate() + 1);
            element.dtFim = dtFim;
          }
        });
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    editaObs(item) {
      this.itemSelecionado = item;
      this.dialogObs = true;
    },
    async saveObs() {
      await util.limparObjeto(this.validacao, false);
      this.isUpdating = true;
      try {
        var objCommit = { obs: this.itemSelecionado.obs };
        await api.put(`${process.env.VUE_APP_URL_WEB_API}adm/reserva/obs/${this.itemSelecionado.id}`, objCommit);

        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        this.dialogObs = false;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    cancela(item) {
      util.limparObjeto(this.validacao, false);
      this.itemSelecionado = item;
      this.dialogConfirm = true;
    },
    async cancelar() {
      try {
        await api.put(`${process.env.VUE_APP_URL_WEB_API}adm/reserva/cancelar/${this.itemSelecionado.id}`);
        this.mensagem = "Reserva cancelada!";
        this.snackbar = true;

        var dadoIndex = this.registros.indexOf(this.itemSelecionado);
        var dadoItem = Object.assign({}, this.itemSelecionado);
        dadoItem.situacao = 0;
        Object.assign(this.registros[dadoIndex], dadoItem);
        setTimeout(() => {
          this.dialogConfirm = false;
        }, 300);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
        setTimeout(() => {
          this.dialogConfirm = false;
        }, 3000);
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO();
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
