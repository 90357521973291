<template>
  <v-container grid-list-md :style="$vuetify.breakpoint.xl ? 'max-width: 70%' : $vuetify.breakpoint.smAndDown ? 'max-width: 95%' : ''">
    <v-layout wrap>
      <v-flex xs4 style="padding: 0px">
        <v-img class="img-responsive" src="bemvindo/esquerda.jpg"> </v-img>
      </v-flex>
      <v-flex xs4 style="padding: 0px">
        <v-img class="img-responsive" src="bemvindo/centro.jpg"> </v-img>
      </v-flex>
      <v-flex xs4 style="padding: 0px">
        <v-img class="img-responsive" src="bemvindo/direita.jpg"> </v-img>
      </v-flex>

      <v-flex xs12 class="text-center" style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Bem-vindo</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.bemvindo"></p>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '80px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  created() {}
};
</script>
