<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">
          <span :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'" style="color: #752921">Cerâmica</span> e
          <span :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'" style="color: #065e2f">Bambu</span>
        </p>
      </v-flex>

      <!-- bambu -->
      <v-flex md4 xs12>
        <v-img class="img-responsive" src="artesanato/bambu.png" :style="`width: ${$vuetify.breakpoint.smAndDown ? '40' : '60'}%; margin: 0 auto`"> </v-img>
      </v-flex>
      <v-flex md8 xs12>
        <v-flex xs12 class="text-center">
          <p style="color: #065e2f" :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.bambu"></p>
        </v-flex>
        <v-flex xs12 class="text-center">
          <p style="color: #065e2f" :class="$vuetify.breakpoint.smAndDown ? 'h4' : 'h3'">Artesanato em Bambu</p>
        </v-flex>
        <v-flex xs12>
          <v-img class="img-responsive" src="artesanato/banquinho_bambu.png" :style="`width: ${$vuetify.breakpoint.smAndDown ? '40' : '20'}%; margin: 0 auto`"> </v-img>
        </v-flex>
      </v-flex>

      <v-flex xs12 style="margin: 40px 0px 40px 0px">
        <v-divider></v-divider>
      </v-flex>

      <!-- cerâmica -->
      <v-flex md4 xs12>
        <v-img class="img-responsive" src="artesanato/ceramica.png" :style="`width: ${$vuetify.breakpoint.smAndDown ? '40' : '60'}%; margin: 0 auto`"> </v-img>
      </v-flex>
      <v-flex md8 xs12>
        <v-flex xs12 class="text-center">
          <p style="color: #752921" :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.ceramica"></p>
        </v-flex>
        <v-flex xs12 class="text-center">
          <p style="color: #752921" :class="$vuetify.breakpoint.smAndDown ? 'h4' : 'h3'">Studio Mossoo de Cerâmica</p>
        </v-flex>
        <v-flex xs12>
          <v-img class="img-responsive" src="artesanato/bow_ceramica.png" :style="`width: ${$vuetify.breakpoint.smAndDown ? '50' : '30'}%; margin: 0 auto`"> </v-img>
        </v-flex>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '100px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
