<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#752921">
      <v-icon color="white" class="mr-2">tag_faces</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Cliente</v-toolbar-title>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              autofocus
              background-color="white"
              v-model="cliente.nome"
              :rules="[validacao.nome == false || validacao.nome, (value) => valida_nome(value) || 'Nome inválido', (value) => !!value || 'Obrigatório.']"
              label="Nome do Cliente"
              maxlength="100"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                cliente.nome = pLetra(cliente.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              background-color="white"
              v-model="cliente.parceiro"
              :rules="[validacao.parceiro == false || validacao.parceiro, (value) => valida_nome(value) || 'Nome do Parceiro inválido']"
              label="Nome do Parceiro(a)"
              maxlength="100"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="
                cliente.parceiro = pLetra(cliente.parceiro);
                validacao.parceiro = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              background-color="white"
              v-model="cliente.email"
              label="E-mail"
              maxlength="100"
              hint="Obrigatório"
              :rules="[validacao.email == false || validacao.email, (value) => !value || value.length > 2 || 'Preencha o e-mail', (value) => valida_email(value)]"
              :disabled="isUpdating"
              @change="
                cliente.email = cliente.email.toLowerCase();
                validacao.email = false;
              "
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md1>
            <v-text-field
              v-model="cliente.ddd"
              label="DDD"
              v-mask="'##'"
              :rules="[validacao.ddd == false || validacao.ddd, (value) => isDDDvalido(value) || 'DDD inválido']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.ddd = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md2>
            <v-text-field
              v-model="cliente.fone"
              label="Telefone"
              v-mask="'###########'"
              :rules="[validacao.fone == false || validacao.fone, (value) => !value || value.length > 7 || 'Número inválido']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.fone = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md9>
            <v-autocomplete
              v-model="cliente.idMunicipio"
              :disabled="isUpdating"
              :filter="filtrarObjetos"
              :items="municipios"
              :rules="[validacao.idMunicipio == false || validacao.idMunicipio]"
              label="Município"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="validacao.idMunicipio = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md12>
            <v-textarea
              v-model="cliente.perfil"
              label="Perfil do Cliente"
              maxlength="1000"
              counter="1000"
              rows="10"
              :disabled="isUpdating"
              :rules="[validacao.perfil == false || validacao.perfil]"
              color="blue-grey lighten-2"
              @change="validacao.perfil = false"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/administrativo/clientes" id="btn-rsn" color="orange lighten-2 white" style="color: white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" depressed @click="salvar('false')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn :loading="isUpdating" color="orange white" style="color: white" id="btn-rsn" outlined @click="salvar('retornar')">
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn :loading="isUpdating" color="blue white" style="color: white" id="btn-rsn" outlined @click="salvar('true')">
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Nova
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      cliente: {
        id: "",
        nome: null,
        parceiro: null,
        email: null,
        ddd: null,
        fone: null,
        idMunicipio: null,
        perfil: null
      },
      validacao: {
        id: false,
        nome: false,
        parceiro: false,
        email: false,
        ddd: false,
        fone: false,
        idMunicipio: false,
        perfil: false,
        mensagemErro: false
      },

      municipios: [],

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.id, this.cliente);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "adm/cliente", this.cliente);
          this.id = retorno.data.id;
          this.cliente.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/administrativo/cliente");
          this.$router.go("/administrativo/cliente");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/administrativo/clientes");
          }, 1000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    valida_email(email) {
      if (email && !util.valida_email(email)) {
        return "E-mail inválido.";
      } else {
        return true;
      }
    },
    isDDDvalido(ddd) {
      return ddd ? util.isDDDvalido(ddd) : true;
    }
  },
  async created() {
    this.POSSUI_DIREITO();
    this.id = this.$route.params.id ? this.$route.params.id : "";

    try {
      const responseMunicipios = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/municipio/lista");
      this.municipios = responseMunicipios.data;

      if (this.id) {
        //* Se é edição, carrega o item
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/" + this.id);
        if (!response.data) {
          this.$router.push("/administrativo/clientes");
        } else {
          this.cliente = response.data;
          this.dtClienteUS = util.dateToStringUS(this.cliente.dtCliente);
        }
      } else {
        this.dtCliente = util.formatDate(new Date());
        this.dtClienteUS = util.dateToStringUS(new Date());
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
