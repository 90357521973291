<template>
  <v-navigation-drawer fixed :clipped="$vuetify.breakpoint.mdAndUp" app v-model="$store.state.drawer">
    <a target="_blank" class="estilo-link-site">
      <v-img class="img-responsive" src="../../public/logo.png" :max-width="$vuetify.breakpoint.smAndDown ? 120 : 180"></v-img>
    </a>

    <v-list dense class="mt-4">
      <template v-for="item in $store.getters.filtroMenu">
        <v-list-group v-if="item.children" v-model="item.model" :key="item.text" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon>
          <v-list-item absolute fixed slot="activator">
            <v-list-item-content>
              <v-list-item-title>
                <span style="font-size: 16px">{{ item.text }}</span>
              </v-list-item-title>
              <!-- itens/títulos com subitens -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.acesso ? child.link : ''">
            <v-list-item-action v-if="child.icon" class="ml-4">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="color: black"
                ><span>{{ child.text }}</span></v-list-item-title
              >
              <!-- subitens -->
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="item.link" :to="item.acesso ? item.link : ''">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="color: black"
              ><span>{{ item.text }}</span></v-list-item-title
            >
            <!-- itens sem subitens -->
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-list>
      <v-list-item-action>
        <v-list-item-content></v-list-item-content>
      </v-list-item-action>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["MOSTRAR_OCULTAR_MENU"])
  },
  data() {
    return {
      modulo: ""
    };
  }
};
</script>

<style>
.primary--text {
  color: #000 !important;
  caret-color: #000 !important;
}
</style>
