var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"hide-overflow fill-height",attrs:{"color":"white lighten-1","lighten":""}},[_c('v-toolbar',{attrs:{"height":"50","color":"#752921"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("landscape")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("Estado")]),_c('v-spacer')],1),_c('v-form',{ref:"formElemento",attrs:{"lazy-validation":""},model:{value:(_vm.elementoValid),callback:function ($$v) {_vm.elementoValid=$$v},expression:"elementoValid"}},[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-linear',{staticClass:"ma-0",attrs:{"active":_vm.isUpdating,"color":"cyan accent-3","height":"3","indeterminate":""}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-autocomplete',{attrs:{"autofocus":"","disabled":_vm.isUpdating,"filter":_vm.filtrarObjetos,"items":_vm.paises,"hint":"Obrigatório","rules":[_vm.validacao.idPais == false || _vm.validacao.idPais, (value) => !!value || 'Obrigatório.'],"label":"Selecione o país","item-text":"nome","item-value":"id","no-data-text":"","autocomplete":"disabled","name":Math.random()},on:{"change":function($event){_vm.validacao.idPais = false}},model:{value:(_vm.uf.idPais),callback:function ($$v) {_vm.$set(_vm.uf, "idPais", $$v)},expression:"uf.idPais"}})],1),_c('v-flex',{attrs:{"xs4":"","md2":""}},[_c('v-text-field',{attrs:{"background-color":"white","label":"Sigla do Estado","maxlength":"2","hint":"Obrigatório","rules":[_vm.validacao.sigla == false || _vm.validacao.sigla, (value) => !!value || 'Obrigatório.', (value) => (value && value.length == 2) || 'Preencha a sigla do estado'],"disabled":_vm.isUpdating,"color":"blue-grey lighten-2","autocomplete":"disabled","name":Math.random()},on:{"change":function($event){_vm.uf.sigla = _vm.uf.sigla.toUpperCase();
              _vm.validacao.sigla = false;}},model:{value:(_vm.uf.sigla),callback:function ($$v) {_vm.$set(_vm.uf, "sigla", $$v)},expression:"uf.sigla"}})],1),_c('v-flex',{attrs:{"xs8":"","md6":""}},[_c('v-text-field',{attrs:{"background-color":"white","label":"Nome do Estado","maxlength":"32","hint":"Obrigatório","rules":[
              _vm.validacao.nome == false || _vm.validacao.nome,
              (value) => !!value || 'Obrigatório.',
              (value) => _vm.valida_nome(value) || 'Nome inválido',
              (value) => value.length > 2 || 'Preencha o nome do estado'
            ],"disabled":_vm.isUpdating,"color":"blue-grey lighten-2","autocomplete":"disabled","name":Math.random()},on:{"change":function($event){_vm.uf.nome = _vm.pLetra(_vm.uf.nome);
              _vm.validacao.nome = false;}},model:{value:(_vm.uf.nome),callback:function ($$v) {_vm.$set(_vm.uf, "nome", $$v)},expression:"uf.nome"}})],1),(_vm.validacao.mensagemErro)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-sheet',{staticClass:"red--text",attrs:{"value":true}},[_vm._v(_vm._s(_vm.validacao.mensagemErro))])],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{attrs:{"id":"flex-wrap"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"to":"/administrativo/ufs","id":"btn-rsn","color":"orange lighten-2 white"}},[_c('v-icon',{attrs:{"id":"icon-rsn","left":""}},[_vm._v("keyboard_arrow_left")]),_vm._v("Retornar ")],1),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"loading":_vm.isUpdating,"color":"blue white","id":"btn-rsn","depressed":""},on:{"click":function($event){return _vm.salvar('false')}}},[_c('v-icon',{attrs:{"id":"icon-rsn","left":""}},[_vm._v("update")]),_vm._v("Salvar ")],1),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"loading":_vm.isUpdating,"color":"orange white","id":"btn-rsn","outlined":""},on:{"click":function($event){return _vm.salvar('retornar')}}},[_c('v-icon',{attrs:{"id":"icon-rsn","left":""}},[_vm._v("update")]),_vm._v("Salvar e Retornar ")],1),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"loading":_vm.isUpdating,"color":"blue white","id":"btn-rsn","outlined":""},on:{"click":function($event){return _vm.salvar('true')}}},[_c('v-icon',{attrs:{"id":"icon-rsn","left":""}},[_vm._v("autorenew")]),_vm._v("Salvar e Novo ")],1)],1),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))]),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }