<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.smAndDown ? 'h2' : 'h1'">Experiências</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.experiencias"></p>
      </v-flex>

      <v-flex xs12 align-center>
        <v-img class="img-responsive" src="experiencias/panoramica.jpg"> </v-img>
      </v-flex>

      <v-flex xs12 :style="`height: ${$vuetify.breakpoint.xs ? '130px' : '60px'};`"></v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
