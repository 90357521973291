<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'">Instruções do Chuveiro a Gás</p>
      </v-flex>
      <v-flex offset-xs2 xs8 offset-md4 md4 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/chuveiroGas.png" :height="$vuetify.breakpoint.xs ? 200 : 400"> </v-img>
      </v-flex>
      <v-flex xs12 align-center style="padding: 0px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Gire a alavanca totalmente para a esquerda e puxe para abrir. Após 2 minutos a água estará quente, então gire para a direita até alcançar a temperatura desejada.
        </p>
        <p :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">OBS: Caso a água não esquente, favor entrar em contato conosco por Whatsapp.</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
