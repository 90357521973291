<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h6' : 'h5'">Instruções do Chuveiro Elétrico</p>
      </v-flex>
      <v-flex offset-xs2 xs8 offset-md4 md4 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/chuveiroGas.png" :height="$vuetify.breakpoint.xs ? 200 : 400"> </v-img>
      </v-flex>
      <v-flex xs12 align-center style="padding: 0px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          Gire a alavanca totalmente para a direita e puxe para abrir. Ajuste a temperatura na regulagem do chuveiro:<br />- Sentido horário (direita) - aquecer;<br />- Sentido
          anti-horário (esquerda) - esfriar; <br /><u>Atenção</u>: <b>Não use o chuveiro com o aquecedor da banheira ligado.</b>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
