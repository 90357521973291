import Vue from "vue";
import Vuetify from "vuetify";
import "./plugins/vuetify";
import App from "./App.vue";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import router from "./router.js"; //* Mecanismo de rotas
import store from "./store";
import "./assets/css/estilos.css";

import currency from "v-currency-field";
import VueTheMask from "vue-the-mask";

Vue.use(currency);
Vue.use(VueTheMask);

Vue.use(Vuetify);

Vue.config.productionTip = false;

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
