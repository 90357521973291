<template>
  <v-app>
    <Header v-if="!$store.state.login"></Header>
    <HeaderAdmin v-if="$store.state.login"></HeaderAdmin>
    <Sidebar v-if="$store.state.login" />
    <v-main v-if="$store.state.login || this.$route.path == '/login'">
      <router-view></router-view>
    </v-main>
    <Footer v-if="!$store.state.login"></Footer>
    <FooterAdmin v-if="$store.state.login"></FooterAdmin>
  </v-app>
</template>
<script>
import VueCookies from "vue-cookies";
import Header from "@/components/Header.vue";
import HeaderAdmin from "@/components/HeaderAdmin.vue";
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";
import FooterAdmin from "./components/FooterAdmin.vue";
export default {
  name: "App",
  components: {
    Header,
    HeaderAdmin,
    Sidebar,
    Footer,
    FooterAdmin
  },
  beforeCreate() {
    if (VueCookies.isKey("token")) {
      if (!this.$store.state.usuario.id) {
        this.$store.dispatch("getUsuario");
      }
    }
  },
  data: () => ({}),
  props: {
    source: String
  }
};
</script>
