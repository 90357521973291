<template>
  <v-card class="hide-overflow fill-height">
    <v-toolbar height="50" color="#752921">
      <v-icon color="white" class="mr-2">grid_on</v-icon>
      <v-toolbar-title style="color: white" class="font-weight-light">Tabelas</v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/administrativo/tabela" style="text-decoration: none">
        <v-btn color="success" fab small>
          <v-icon color="white" title="Incluir">add</v-icon>
        </v-btn>
      </router-link>
    </v-toolbar>
    <div class="px-6 mt-4">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="12" md="5">
          <v-text-field
            background-color="white"
            class="mt-2 mb-2 elevation-0"
            v-model="filtroPesquisa"
            append-icon="search"
            label="Digite para pesquisar..."
            placeholder="Pesquisa pelo nome da cabana"
            filled
            hide-details
            @keyup.enter="limpaFiltro"
            @click:append="limpaFiltro"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="registros"
        :hide-default-footer="false"
        :options.sync="options"
        :no-data-text="mensagemPesquisa"
        :footer-props="rodape"
        :disable-sort="carregando"
        :disable-pagination="carregando"
      >
        <template v-slot:item.actions="{ item }">
          <router-link :to="'/administrativo/tabela/' + item.id" style="text-decoration: none">
            <v-icon color="blue" title="Editar">edit</v-icon>
          </router-link>
        </template>
        <template v-slot:item.dtInicio="{ item }">
          {{ formatDate(item.dtInicio) }}
        </template>
        <template v-slot:item.vlrCustoFixo="{ item }">
          {{ formatCurrency(item.vlrCustoFixo) }}
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2 marg-bt">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </div>
    </div>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: 80 },
        { text: "ID", value: "id" },
        { text: "Cabana", value: "nome", divider: true },
        { text: "Início", value: "dtInicio" },
        { text: "Custo", value: "vlrCustoFixo", divider: true },

        { text: "Mínimo Úteis", value: "minimoDiasUtil" },
        { text: "Mínimo Fins Semana", value: "minimoDiasFimDS" },
        { text: "Mínimo Feriados", value: "minimoDiasFer" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/tabela?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    },
    formatDate(date) {
      return util.formatDate(date);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO();
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
