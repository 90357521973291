<template>
  <v-container fluid grid-list-md>
    <v-layout wrap>
      <v-flex xs12 md7>
        <v-checkbox v-model="naoVisualizadas" :label="naoVisualizadas ? 'Somente mensagens não visualizadas' : 'Todas as mensagens'" @change="limpaFiltro"></v-checkbox>
      </v-flex>

      <v-flex xs1 offset-md-1 md1>
        <v-btn color="blue lighten-2" fab small>
          <v-icon color="white" @click="carregarMensagens" title="Atualizar">refresh</v-icon>
        </v-btn>
      </v-flex>

      <v-flex xs12>
        <v-data-table mobile-breakpoint="0" :headers="headers" :items="mensagens" hide-default-header hide-default-footer no-data-text="- Não há mensagens -" height="510">
          <template v-slot:item.data="{ item }">
            <span :class="item.visualizado ? 'grey--text' : ''" v-html="formatDateTime(item.data)"></span>
          </template>
          <template v-slot:item.resumo="{ item }">
            <span
              :class="item.visualizado ? 'grey--text' : ''"
              v-html="
                '<div style=\'margin-top: 15px;' +
                (item.resumo.includes('Aguarde') || item.resumo.toUpperCase().includes('ERRO') || item.titulo.includes('Erro') ? ' color: red;' : '') +
                '\'><h4>' +
                item.titulo +
                (item.dtMovimento ? ' ' + formatDate(item.dtMovimento) : '') +
                '</h4><p>' +
                item.resumo +
                '</p>' +
                (idUsuario ? '' : '<p>' + item.usuario + '</p>')
              "
            ></span>
          </template>
          <template v-slot:item.visualizado="{ item }">
            <v-btn icon ripple @click="cliqueMensagem(item)">
              <v-icon :color="item.visualizado ? 'grey' : 'teal darken-2'">{{ mensagemIcone[item.tipo] }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex text-xs-center style="margin-top: 20px; padding-bottom: 20px">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </v-flex>
      <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    </v-layout>
  </v-container>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import download from "downloadjs";
export default {
  data() {
    return {
      headers: [
        { text: "", value: "data" },
        { text: "", value: "resumo" },
        { text: "", value: "visualizado" }
      ],

      mensagem: "",
      snackErro: false,

      idUsuario: 0,
      naoVisualizadas: 1,
      mensagemIcone: [],
      mensagens: [],
      registrosPagina: 5,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1
    };
  },
  methods: {
    limpaFiltro() {
      this.paginaAtual = 1;
      this.carregarMensagens();
    },
    carregarMensagens() {
      var urlApiMensagem = process.env.VUE_APP_URL_WEB_API + "adm/mensagem";

      urlApiMensagem += "?pagina=" + this.paginaAtual;

      if (this.naoVisualizadas) {
        urlApiMensagem += "&naoVisualizadas=1";
      }

      //* Carrega as mensagens
      api
        .get(urlApiMensagem)
        .then((response) => {
          this.mensagens = response.data.data;
          this.totalNaoVisualizado = response.data.totalNaoVisualizado;
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.$emit("update:mensagensCarregadas", this.totalNaoVisualizado);
        })
        .catch((e) => {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA!";
            this.snackErro = true;
            setTimeout(() => {
              this.$store.dispatch("deslogarUsuario");
            }, 2000);
          }
        });
    },
    dialogMensagensAbrir() {},
    async cliqueMensagem(mensagem) {
      try {
        //* Verifica o tipo de mensagem
        if (!mensagem.resumo.includes("Aguarde")) {
          download(process.env.VUE_APP_URL_WEB_API + mensagem.arquivo);
        }

        //* Define como item visualizado
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/mensagem/visualizado/" + mensagem.id);

        //* Define como item visualiado
        mensagem.visualizado = 1;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    formatDateTime(date) {
      return util.formatDateTime(date).replace(" ", "<br/>");
    }
  },

  // mounted() {
  //   setInterval(() => {
  //     this.carregarMensagens();
  //   }, 300000);
  // },

  watch: {
    paginaAtual() {
      this.carregarMensagens();
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    //* Define os icones das mensagens
    this.mensagemIcone["DOWNLOAD"] = "cloud_download";
    this.mensagemIcone["TEXTO"] = "mail";
    this.mensagemIcone["PDF"] = "cloud_download";

    if (this.roteiro) {
      this.registrosPagina = 10;
      this.naoVisualizadas = 0;
    }

    //* Carrega as mensagens
    this.carregarMensagens();
  }
};
</script>
