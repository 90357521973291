import Vue from "vue";
import VueCookies from "vue-cookies";
import axios from "axios";

Vue.use(VueCookies);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_WEB_API
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = `Bearer ${VueCookies.get("token")}`;
    if (token) {
      config.headers.Authorization = token;
      config.headers.Versao = process.env.VUE_APP_VERSAO;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint, {
      headers: { "Access-Control-Allow-Origin": "*" }
    });
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  loginAuth(body) {
    return axios.post(process.env.VUE_APP_URL_WEB_API + "login/auth-token", body);
  },
  login(body) {
    return axios.post(process.env.VUE_APP_URL_WEB_API + "login/access-token", body);
  }
};
