import Vue from "vue";
import VueCookies from "vue-cookies";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import menu from "./menu";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    bemvindo: menu.bemvindo,
    sobre1: menu.sobre1,
    sobre2: menu.sobre2,
    sobre3: menu.sobre3,
    sobre4: menu.sobre4,
    blog: menu.blog,
    bambu_bamboo1: menu.bambu_bamboo1,
    bambu_bamboo2: menu.bambu_bamboo2,
    bambu_bamboo3: menu.bambu_bamboo3,
    araucaria1: menu.araucaria1,
    araucaria2: menu.araucaria2,
    araucaria2_1: menu.araucaria2_1,
    araucaria2_2: menu.araucaria2_2,
    hospedagem: menu.hospedagem,
    gracillis: menu.gracillis,
    bambu: menu.bambu,
    ceramica: menu.ceramica,
    experiencias: menu.experiencias,
    contato: menu.contato,

    versao: process.env.VUE_APP_VERSAO,
    mensagemErro: {
      tipo: "",
      mensagem: ""
    },
    login: false,
    usuario: {
      id: "",
      nome: "",
      login: ""
    },
    itens: [],
    modulo: "",
    drawer: null, //utilizado para mostrar e esconder o menu
    itensAministrativo: menu.itensAministrativo
  },
  getters: {
    filtroMenu: (state) => {
      return state.itens;
    }
  },
  mutations,
  actions
});
