<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 align-center style="padding: 0px">
        <p class="p13" style="color: green">
          <b>Para versão {{ resumida ? "completa" : "resumida" }}, clique <a @click="resumida = !resumida">aqui</a>.</b>
        </p>

        <span v-if="!resumida" :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          <ul>
            <li>
              <b>Localização</b>: A cabana Gracillis, de sua hospedagem, fica no <a class="estilo-link-site" href="https://espacomossoo.com.br" target="_blank">Espaço Mossoo</a>,
              distante cerca de 14 km do centro de Alfredo Wagner. Para sua localização e suporte na viagem, favor <b>baixar</b> (para funcionar offline)
              <a class="estilo-link-site" href="https://maps.app.goo.gl/qdJEsx8wNzvyxke77" target="_blank">nosso mapa no Google Maps</a>, pois em parte do trajeto o sinal de
              telefone e internet estarão ausentes. Caso esteja utilizando Waze* ou GPS* do carro, acesse a descrição do local
              <a class="estilo-link-site" @click="dialog = true">aqui</a>. <br /><span :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">
                *Os últimos 400 metros são em estrada de terra e atualmente somente o Maps tem acesso a esta estrada.</span
              >
            </li>
            <br />
            <li><b>Horário de check-in é às 15h e check-out às 12h.</b></li>
            <br />
            <li>
              <b>O que está incluído</b>:
              <ul>
                <li>
                  A hospedagem é com café da manhã valorizando os produtos locais e preferencialmente orgânicos. A cesta com o café completo é entregue na cabana às 9hs da manhã
                  (deixamos na varanda, em cima do banquinho ao lado da porta). Favor informar antecipadamente qualquer intolerância ou situação especial, bem como necessidade do
                  serviço em horário especial.
                </li>
                <li>
                  A cabana é equipada com roupa de cama e banho, roupões e rede, sais de banho, sabonete, papel higiênico e os itens de cozinha: taças para vinho, para espumante e
                  para água; pratos, pratinhos, xícaras, canecas e talheres. Também dispõe de chaleira elétrica, frigobar, forno de microondas e mini pia para emergências.
                </li>
                <li>
                  O hóspede tem acesso a todo o espaço para caminhadas e caso queira ter maior contato com a natureza, sugerimos trazer calçado adequado e roupas leves e de mangas
                  para maior segurança, principalmente nos dias quentes. Caso tenham interesse em passeio guiado para entender o conceito e as técnicas utilizadas, teremos a maior
                  satisfação em acompanhá-los, é só agendar o melhor horário.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>Alimentação e Experiências</b>:
              <a class="estilo-link-site" href="https://turismoalfredowagner.com.br" target="_blank">Alfredo Wagner</a>
              é um pequeno município com um pouco mais de dez mil habitantes, mas com uma extensão territorial considerável que abriga áreas de preservação e cenários de tirar o
              fôlego. Vale a pena explorar um pouco da nossa linda cidade. Para quem preferir se aventurar seguindo a Serra Catarinense, as opções vão aumentando e são passeios de
              um dia para belas vinícolas, ou Serra do Corvo Branco ou Rio do Rastro. <br /><br />
              Para quem preferir ficar mais sossegado e descansar, temos o <a class="estilo-link-site" @click="funcCardapio">nosso cardápio</a>. E quem quiser dar uma voltinha pela
              cidade, seguem algumas opções:<br />
              <ul>
                <li>
                  <a class="estilo-link-site" href="https://turismoalfredowagner.com.br/local/salto-das-aguas" target="_blank">Restaurante Salto das Águas</a>: Distante 12 km,
                  serve buffet completo com saladas e grelhados.
                </li>
                <li>
                  <a class="estilo-link-site" href="https://turismoalfredowagner.com.br/local/prazer-da-gula" target="_blank">O Cheff Restaurante</a>: : Centro de Alfredo Wagner -
                  14 km, com opções de lanches, porções e <i>a la carte</i>.
                </li>
                <br />
                Tem alguma situação ou necessidade especial? Teremos a maior alegria em ajudá-lo.
              </ul>
            </li>
            <br />
            <li>
              <b>Telefone, Energia e Internet</b>:
              <ul>
                <li>Não temos sinal de telefone no Espaço Mossoo, usamos internet e em caso de emergências usamos antena para captar sinal.</li>
                <li>
                  A voltagem em Alfredo Wagner é de 220v, em 2023 implantamos a energia solar, que nos proporciona suficiência durante dias ensolarados e algumas horas também a
                  noite, mesmo com algum transtorno de fornecimento pela companhia elétrica CELESC-SC.
                </li>
                <li>
                  A internet funciona via satélite e é de boa qualidade, permitindo trabalho remoto ou apenas ouvir uma boa seleção de música, um filme ou uma série enquanto
                  descansa. Dentro da cabana a rede é <i>Gracillis</i> e a senha <i>gracillis</i>.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>Água</b>: Utilizamos água de captação própria (caxambu), sistema de tratamento completo e disponibilizamos filtro de barro, altamente eficiente, para manter o
              frescor e as propriedades da água coletada, que com análises anuais apresenta segurança para consumo.
            </li>
            <br />
            <li>
              <b>Compensação de Carbono</b>: Sabemos que deixamos pegadas por onde passamos e queremos que sua viagem além de revigorar o corpo, a mente e o espírito, cause
              impactos positivos para as gerações futuras. No dia do seu check-out poderemos disponibilizar uma muda de uma espécie nativa com terra de nosso minhocário para ajudar
              na neutralização de carbono de sua viagem. Entre em contato conosco.
            </li>
          </ul>
          <br />
          <p>
            Somos um casal que escolheu viver aqui, numa rotina mais leve e cercados pela natureza. Respeitamos os recursos naturais, respeitamos as pessoas e suas individualidades
            e fomos muito bem recebidos pelos moradores desse local mágico, que também respeitamos a sua cultura e cultivamos boas trocas de experiências.
          </p>
          <br />
          <p>
            Vocês estão chegando em um local que desde 2009 não tem aplicação de agrotóxicos e produtos que causem impacto às espécies locais. Com muita felicidade recebemos visita
            de espécies que não circulavam mais pela região, mas também tem a presença de animais peçonhentos, principalmente no verão. Para que a sua experiência seja sem
            imprevistos, favor atentar para:
          </p>
          <ul>
            <li>Manter a porta da cabana <b>sempre fechada</b>;</li>
            <li>Após usar a rede, sempre recolha e guarde dentro da cabana;</li>
            <li>Use <u>repelente</u>, <u>calçado fechado</u> e confortável e preferencialmente roupas de <u>manga longa</u> para caminhadas e passeios;</li>
            <li><b>Ao sair à noite</b>, use lanterna de emergência ou do celular e tome <b>cuidado</b> onde <u>pisar</u> ou <u>colocar a mão</u>;</li>
          </ul>
          <br />
          <p>Em caso de dúvidas, favor nos acionar, teremos a maior alegria em ajudá-los.</p>
          <p>Esperamos vocês com todo o carinho.</p>
          <p>Mila e Tarcísio</p>
        </span>

        <span v-if="resumida" :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'">
          <ul>
            <li>
              <b>Localização</b>: 14 km do centro de Alfredo Wagner. Favor <b>baixar</b> (para funcionar offline)
              <a class="estilo-link-site" href="https://maps.app.goo.gl/qdJEsx8wNzvyxke77" target="_blank">nosso mapa no Google Maps</a>. Caso utilize Waze* ou GPS* do carro,
              acesse a descrição do local <a class="estilo-link-site" href="#" @click="dialog = true">aqui</a>. <br /><span :class="$vuetify.breakpoint.smAndDown ? 'p12' : 'p13'">
                *Os últimos 400 metros são em estrada de terra acessível somente pelo Maps.</span
              >
            </li>
            <br />
            <li><b>Check-in: 15h - Check-out: 12h.</b></li>
            <br />
            <li>
              <b>O que está incluído</b>:
              <ul>
                <li>Café da manhã entregue na cabana às 9hs da manhã (deixamos ao lado da porta). Favor informar antecipadamente qualquer intolerância ou situação especial.</li>
                <li>
                  A cabana é equipada com roupa de cama e banho, roupões e rede, sais de banho, sabonete, papel higiênico, itens de cozinha: taças para vinho, para espumante e para
                  água; pratos, pratinhos, xícaras, canecas, talheres, chaleira elétrica, frigobar, forno de microondas e mini pia.
                </li>
                <li>O hóspede tem acesso a todo o espaço para caminhadas.</li>
              </ul>
            </li>
            <br />
            <li>
              <b>Alimentação e Experiências</b>:
              <a class="estilo-link-site" href="https://turismoalfredowagner.com.br" target="_blank">Alfredo Wagner</a>
              é um pequeno município que abriga áreas de preservação e cenários de tirar o fôlego. Seguindo a Serra Catarinense, as opções vão aumentando: belas vinícolas, Serra do
              Corvo Branco e do Rio do Rastro.
              <br /><br />
              Se quiser ficar sossegado acesse o <a class="estilo-link-site" @click="funcCardapio">nosso cardápio</a> Se quiser sair, seguem algumas opções:<br />
              <ul>
                <li>
                  <a class="estilo-link-site" href="https://turismoalfredowagner.com.br/local/salto-das-aguas" target="_blank">Restaurante Salto das Águas</a>: Distante 12 km,
                  serve buffet completo com saladas e grelhados.
                </li>
                <li>
                  <a class="estilo-link-site" href="https://turismoalfredowagner.com.br/local/prazer-da-gula" target="_blank">O Cheff Restaurante</a>: : Centro de Alfredo Wagner -
                  14 km, com opções de lanches, porções e <i>a la carte</i>.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>Telefone, Energia e Internet</b>:
              <ul>
                <li>Sinal de telefone, não há.</li>
                <li>
                  Voltagem: 220v, com auxílio da energia solar, que nos proporciona alguma suficiência em caso de possível transtorno de fornecimento pela companhia elétrica
                  CELESC-SC.
                </li>
                <li>Internet via satélite, de boa qualidade. Dentro da cabana a rede é <i>Gracillis</i> e a senha <i>gracillis</i>.</li>
              </ul>
            </li>
            <br />
            <li><b>Água</b>: Captação (caxambu) e tratamento próprio, analisada anualmente.</li>
            <br />
            <li>
              <b>Compensação de Carbono</b>: No dia do seu check-out poderemos disponibilizar uma muda de uma espécie nativa para ajudar na neutralização de carbono de sua viagem.
              Entre em contato conosco.
            </li>
          </ul>
          <br />
          <p>Para que a sua experiência seja sem imprevistos, favor atentar para:</p>
          <ul>
            <li>Manter a porta da cabana <b>sempre fechada</b>;</li>
            <li>Após usar a rede, sempre recolha e guarde dentro da cabana;</li>
            <li>Use <u>repelente</u>, <u>calçado fechado</u> e confortável e preferencialmente roupas de <u>manga longa</u> para caminhadas e passeios;</li>
            <li><b>Ao sair à noite</b>, use lanterna de emergência ou do celular e tome <b>cuidado</b> onde <u>pisar</u> ou <u>colocar a mão</u>;</li>
          </ul>
          <br />
          <p>Em caso de dúvidas, favor nos acionar, teremos a maior alegria em ajudá-los.</p>
          <p>Esperamos vocês com todo o carinho.</p>
          <p>Mila e Tarcísio</p>
        </span>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-text>
          <v-icon @click="dialog = false" color="darken-1" style="right: 20px; position: absolute; z-index: 1">cancel</v-icon>
          <v-img class="img-responsive" src="hospedagem/mapa.png" min-width="600"> </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    cardapio: { default: false }
  },
  data() {
    return {
      dialog: false,
      resumida: false
    };
  },
  methods: {
    funcCardapio() {
      this.$emit("update:cardapio", true);
    }
  }
};
</script>
