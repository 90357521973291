<template>
  <v-card class="hide-overflow fill-height">
    <v-row>
      <v-progress-linear :active="carregando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="440">
          <v-calendar
            locale="pt-BR"
            ref="calendar"
            v-model="focus"
            type="month"
            color="default"
            :events="registros"
            :event-color="getEventColor"
            @click:event="eventoSelecionado"
            @click:date="diaSelecionado"
            @change="updateRange"
            style="text-align: center"
            :show-month-on-first="false"
            :start="dtInicial"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: {
    tipo: { default: null },
    dtSelecionada: { default: null },
    dtInicio: { default: null }
  },
  data() {
    return {
      filtroPesquisa: "",
      registros: [],
      idCabana: 1,
      carregando: true,
      mensagem: "",
      snackErro: false,
      mes: 0,
      ano: 0,
      focus: "",
      dtInicial: new Date(),
      dtLimite: null
    };
  },
  methods: {
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateRange({ start, end }) {
      var dtLimiteC = new Date();
      dtLimiteC.setHours(0);
      dtLimiteC.setMinutes(0);
      dtLimiteC.setSeconds(0);
      dtLimiteC.setMilliseconds(0);
      var dtInicioC = util.parseDateUS(start.date);
      var dtFimC = util.parseDateUS(end.date);
      if (!process.env.VUE_APP_TESTE && dtFimC < dtLimiteC) {
        this.mensagem = "Período indisponível";
        this.snackErro = true;
        this.registros = [];
        return;
      }
      this.mes = dtInicioC.getMonth() + 1;
      this.ano = dtInicioC.getFullYear();

      this.pesquisaDados();
    },
    diaSelecionado({ date }) {
      var dia = this.registros.find((dado) => dado.dtEntrada == date);
      if (dia && dia.dtEntrada) {
        this.$emit("update:dtSelecionada", date);
      }
    },
    eventoSelecionado(event) {
      this.$emit("update:dtSelecionada", event.day.date);
    },
    async pesquisaDados() {
      if (!this.idCabana || !this.mes || !this.ano) {
        return;
      }
      this.dtLimite = null;
      //sinaliza que está carregando
      this.carregando = true;
      this.registros = [];

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(`${process.env.VUE_APP_URL_WEB_API}public/mapa?idCabana=${this.idCabana}&mes=${this.mes}&ano=${this.ano}`);
        if (response.data && response.data.length) {
          //seta as variaveis com o retorno
          const elementos = response.data;

          var data = null;
          if (this.dtInicio && !this.dtLimite) {
            this.dtLimite = util.parseDateUS(this.dtInicio);
            this.dtLimite.setMonth(this.dtLimite.getMonth() + 1);
          }
          for (let index = 0; index < elementos.length; index++) {
            var element = elementos[index];

            if (element.id) {
              if (!this.dtInicio) {
                continue;
              }
            }
            var dia = util.parseDateUS(element.data);
            var dtHoje = new Date();
            dtHoje.setDate(dtHoje.getDate() - 1);
            if (!process.env.VUE_APP_TESTE && dia < dtHoje) {
              continue;
            }
            if (this.dtInicio) {
              dtHoje = util.parseDateUS(this.dtInicio);
              dtHoje.setDate(dtHoje.getDate() + 1);
              if (dia < dtHoje || dia > this.dtLimite) {
                continue;
              }
            }

            if (!element.dtEntrada) {
              element.dtEntrada = util.parseDateUS(element.data);
            } else {
              element.dtEntrada = util.parseDateUS(element.dtEntrada);
            }
            if (!element.dtFim || this.dtInicio) {
              element.dtFim = util.parseDateUS(element.data);
            } else {
              element.dtFim = util.parseDateUS(element.dtFim);
            }
            if (this.dtInicio && element.id) {
              this.dtLimite = util.parseDateUS(element.dtEntrada);
            }

            var dtSaida = util.parseDateUS(element.dtFim);
            dtSaida.setDate(dtSaida.getDate() + 1);
            element.dtSaida = util.dateToStringUS(dtSaida);

            var sdf = false;
            if (!data) {
              data = util.parseDateUS(element.data);
            }
            var dataOut = util.parseDateUS(element.dtEntrada);
            dataOut.setDate(dataOut.getDate());
            if (!process.env.VUE_APP_TESTE && dataOut < data) {
              continue;
            }

            var vlrVenda = 0;
            var x = index + 0;
            do {
              if (element.vlrVenda && !this.dtInicio) {
                vlrVenda = element.vlrVenda;
              } else {
                vlrVenda = elementos[x].vlrMossoo;
              }

              if (elementos[x].feriado) {
                sdf = true;
              }
              if (data.getDay() == 0 || data.getDay() == 6) {
                sdf = true;
              }

              x++;
              data.setDate(data.getDate() + 1);
            } while (data <= element.dtFim);

            this.registros.push({
              id: element.id,
              name: util.formatCurrency(vlrVenda) + (element.feriado && this.$vuetify.breakpoint.lgAndUp ? " - " + element.feriado : ""),
              start: element.dtEntrada,
              end: element.dtFim,
              color: element.id ? (sdf ? "orange darken-3" : "orange lighten-1") : sdf ? "green darken-3" : "green lighten-1",
              timed: false,
              feriado: element.feriado,
              vlrVenda: vlrVenda,
              dtEntrada: util.dateToStringUS(element.dtEntrada),
              dtSaida: util.dateToStringUS(element.dtSaida)
            });
          }
        } else {
          this.mensagem = "Período indisponível";
          this.snackErro = true;
        }

        //fecha o loading
        this.carregando = false;
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
        } else {
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor, true);
    },
    formatDate(date) {
      return util.formatDate(date);
    }
  },
  watch: {
    dtInicio(val) {
      if (val) {
        this.dtInicial = util.parseDateUS(val);
        this.dtInicial.setDate(this.dtInicial.getDate() + 1);
        this.pesquisaDados();
      }
    }
  },
  async beforeMount() {
    this.pesquisaDados();
  }
};
</script>
