<template>
  <v-footer inset class="white" elevation="6" app>
    <v-container grid-list-md :style="$vuetify.breakpoint.xl ? 'max-width: 70%' : $vuetify.breakpoint.smAndDown ? 'max-width: 95%' : ''">
      <v-layout wrap align-center>
        <v-flex md5 xs6 class="white text-left">
          <span class="p12">
            <strong>
              <a target="_blank" class="estilo-link-site" href="http://espacomossoo.com.br">Espaço Mossoo &copy;</a>
            </strong>
            {{ versao }} - Alfredo Wagner - Santa Catarina - Brasil
          </span>
        </v-flex>

        <v-flex xs1 class="justify-center">
          <v-img
            :style="`position: absolute; bottom: ${$vuetify.breakpoint.xs ? '60px' : '20px'}; opacity: 0.7;`"
            class="img-responsive"
            src="folha.png"
            :max-width="$vuetify.breakpoint.smAndDown ? 80 : 120"
          ></v-img>
        </v-flex>

        <v-spacer></v-spacer>

        <v-flex md3 xs5 class="white text-right">
          <v-btn icon href="mailto:mossooespaco@gmail.com">
            <v-icon title="Email" color="#5b361b">mail</v-icon>
          </v-btn>

          <v-btn icon href="https://www.facebook.com/espacomossoo" target="_blank">
            <v-icon title="Facebook" color="#3b5998">mdi-facebook</v-icon>
          </v-btn>

          <v-btn icon href="https://www.instagram.com/espacomossoo/" target="_blank">
            <v-icon title="Instagram" color="#517fa4">mdi-instagram</v-icon>
          </v-btn>

          <v-btn icon href="https://br.pinterest.com/mossooespaco/" target="_blank">
            <v-icon title="Pinterest" color="#E60023">mdi-pinterest</v-icon>
          </v-btn>

          <!-- <v-btn icon disabled>
            <v-icon disabled title="Youtube" color="#bb0000">mdi-youtube</v-icon>
          </v-btn> -->

          <v-btn icon href="https://api.whatsapp.com/send/?phone=+5548999823931&text&type=phone_number&app_absent=0&text=Olá Espaço Mossoo..." target="_blank">
            <v-icon title="Whatsapp" color="#34af23">mdi-whatsapp</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      versao: ""
    };
  },
  async created() {
    try {
      this.versao = process.env.VUE_APP_VERSAO;
    } catch {}
  }
};
</script>
