<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 style="margin-top: 20px">
        <p :class="$vuetify.breakpoint.smAndDown ? 'h4' : 'h3'">Cabana Gracillis</p>

        <p :class="$vuetify.breakpoint.smAndDown ? 'p16' : 'p20'" v-html="$store.state.gracillis"></p>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/externa_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 110 : 400"> </v-img>
      </v-flex>

      <v-flex xs6 style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/banheiro1_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 70 : 230"> </v-img>
      </v-flex>
      <v-flex xs6 style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/banheiro2_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 70 : 230"> </v-img>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/quarto_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 100 : 380"> </v-img>
      </v-flex>

      <v-flex xs12 align-center style="padding: 0px">
        <v-img class="img-responsive" src="hospedagem/bambu_gracillis.jpg" :height="$vuetify.breakpoint.xs ? 40 : 100"> </v-img>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    reservas: { default: false },
    contato: { default: false }
  },
  data() {
    return {};
  },
  methods: {
    funcReservas() {
      this.$emit("update:reservas", true);
    },
    funcContato() {
      this.$emit("update:contato", true);
    }
  }
};
</script>
